.freightForm.ui.form {
    margin: auto;
    width: 60rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 98%;
}
.freightContainer h2{
    padding: 0.5rem;
}
.freightForm.ui.form .field > div > label {
    font-weight: 100;
    font-size: 0.73rem;
    width: 100%;
}
.freightForm.ui.form .field > div {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    text-align: center;
}
.shipWeightWrap {
    display: inline-flex;
    align-items: center;
}
.freightForm.ui.form .field > div > label > span {
    color: #FF0000;
    font-weight: bold;
    font-size: 0.9rem;
}
.freightForm.ui.form .field input {
    width: 7.5rem;
    border: 1px solid #000000ad;
    border-radius: 0;
    padding: 0.5rem 0.25rem;
}
.freightForm.ui.form .field {
    font-weight: 100;
}

.formSetRow_1 {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    
}
.formSetRow_1 .field {
    flex-basis: 20%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
 
}
.formSetRow_1 .field input {
    text-align: center;
}
.formSetRow_2 {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0.5rem 0 1rem 0;
}
.formSetRow_2 .field > label {
    display: inline-block !important;
    padding-right: 0.15rem;

}
.formSetRow_2 .field:nth-of-type(1) {
    width: 40%;
}
.formSetRow_2 p{
    font-size: 0.8rem;
    padding-right: 0.5rem;
}
.freightForm.ui.form .formSetRow_2 .field >div > label, .freightForm.ui.form .formSetRow_2 .field span {
    font-weight: bold;
    font-size: 1rem;
    width: auto;
    display: inline-block;
    padding-right: 0.25rem;
}
.actionBtnWrap {
    width: 100%;
    margin-bottom: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.actionBtnWrap button {
    background: #000;
    color: #fff;
    padding: 0.8rem 0.75rem;
    margin: 0 0.5rem;
    text-transform: uppercase;
    width: 9rem;
    cursor: pointer;
}
.actionBtnWrap button:hover {
    background-color: #424242;
}
.freightContainer .ui.form .field.field input:-webkit-autofill {
    background-color: #fff !important;
    border: 1px solid #000000ad !important;
    box-shadow: none !important;
}
.freightContainer .ui.form .field.field input:-webkit-autofill:focus {
    background-color: #fff !important;
    border: 1px solid #000000ad !important;
    box-shadow: none !important;
}
.ui.modal.EstimatorPopup >.content {
    background: #f2f2f2;
}
.EstimatorPopup .close.icon {
    color: #000000;
    top: 0.5rem !important;
    right: 0.5rem !important;
}
.dollarSign {
    position: relative;
}
.formSetRow_2  i {
    font-size: 0.85rem;
    color: #000;
    line-height: 1;
}
.desktop_menu > div.loaderCenter {
    margin-top: 0 !important;
}
.ui.dimmer .EstimatorPopup .ui.loader:before {
    border-color: rgb(3 3 3 / 15%);
}
.ui.dimmer .EstimatorPopup .ui.loader:after {
    border-color: #a79999 transparent transparent;
}
.frightError {
    color: red;
    padding: 0.25rem 0;
    font-size: 0.7rem;
    font-weight: bold;
}