.options-label {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.totalRDC{
  margin-top: 0.25rem;
}
.title {
  font-family: HelveticaNeueBold !important;
  background-color: #f0f0f0 !important;
}
.content {
  font-family: HelveticaNeue !important;
  font-weight: bold;
}
.page-header.pdp_details-header{
  margin-right: 0;
}
.dealer-program{
  cursor: pointer;
  text-decoration: underline;
}
.listingProduct{
  font-weight: normal;
}
.product_card-compact .listingProduct .plp_add_to_cart-compact{ 
  float: right;
  width: 9rem;
  margin-top: 0.3rem;
}
.gridbold b{
  font-weight: normal !important;
}

#Mobileqty {
  margin-left: 6.4rem;
}

#MobileAvailable {
  padding-left: 0rem;
}
.disabled{
  cursor: not-allowed !important;
  color: #919191 !important;
}

.add-to-cart-modal .close.icon {
  color: #000000;
  top: 0.5rem !important;
  right: 0.5rem !important;
}

.add-to-cart-modal {
  font-family: HelveticaNeue !important;
  font-weight: bold;
}
.add-to-cart-modal .description {
  font-size: 1rem;
  height: 11rem;
}
.add-to-cart-modal .order-type {
  font-family: HelveticaNeueBold !important;
}
#productDetailInfo {
  padding: 0.5rem 1em;
  border: none;
  margin: 0rem;
  background-color: #f2f2f2;
  box-shadow:none !important;
}

#assortment_info {
  padding: 0rem;
  border: none;
  margin: 0rem;
  background-color: #f2f2f2;
  box-shadow: none;
}

.askTrueValue {
  width: 50%;
  text-decoration: underline;
  display: inline-block;
}
.suggestedRetailRow {
  background-color: #d6d8db;
  color: #000000;
  font-weight: bold;
}

.productSpecification {
  background-color: #181818;
  color: #FFFFFF;
  font-family: HelveticaNeueBold;
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  padding:0.6rem;
  margin-bottom: 0.5rem
}

.productDetailSpecification,.onlineRestrication {
  background-color: #afafaf;
  color: #000;
  font-family: HelveticaNeueBold;
  font-size: 1rem;
  padding-top: 0.5rem;
}

.productSpecSubTitle {
  padding-left: 0.5rem;
}

.strip_table.table td {
  padding: 6px !important;
  margin: 0 !important;
}

.strip_table td {
  border: 1px solid #cacaca !important;
  text-align: left;
  width: 25%
}

.strip_table {
  padding-bottom: 1rem !important;
  margin-top:0.5rem !important;
}

.strip_table td:nth-child(odd) {
  background-color: #dddddd;
}

.whiteback {
  background-color: #FFFFFF;
}

#Detailed_specification {
  font-family: HelveticaNeue;
  padding-bottom: 0rem!important;
  padding-top:0rem!important;
  letter-spacing: 0.05rem;
}

.marginall {
  padding: 10px;
  margin-top: 40px
}
#onlineRestrication {
  font-family: HelveticaNeue;
  padding-top: 0;
  padding-bottom: 0rem;
  letter-spacing: 0.05rem;
}
.link-style {
  margin-left: 0.25rem;
}
#onlineResDialog {
  position: fixed;
  z-index: 999;
  width: 39rem;
  max-width: 80%;
  top: 7rem;
  background: #fff;
  left: 50%;
  transform: translateX(-50%);
}
#onlineResDialog .ui-dialog-container {
  padding: 1rem;
}
.online_dialog_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.online_dialog_title h3 {
  margin-bottom: 0;
  font-family: HelveticaNeueBold;
}
.online_dialog_close {
  font-family: HelveticaNeueBold;
  margin-right: 1rem;
  font-size: large;
  cursor: pointer;
}
.online_dialogContent p {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}
.col_widthVal {
  min-width: 3rem;
}
.online_dialogContent a {
  cursor: pointer;
}

.grey_background {
  opacity: 0.5;
  background-color: #f2f2f2;
}

#competitorDialog {
  width: 39rem;
  height: auto;
  background-color: #ffffff;
  position: fixed;
  opacity: 1;
  overflow: hidden;
  margin-left: -19.5rem;
  z-index: 1000;
  left: 50%;
  top: 15rem;
}

.dialog_title {
  float: left;
  font-weight: bold;
  margin-left: 1rem;
  margin-top: 1rem;
  font-family: HelveticaNeueBold;
  font-size: large;
}

.dialog_close {
  float: right;
  margin-left: 1rem;
  margin-top: 1rem;
  font-family: HelveticaNeueBold;
  margin-right: 1rem;
  font-size: large;
  cursor: pointer;
}

.competitorDetails {
  margin-top: 4rem;
}

#competitorTable {
  table-layout: auto;
  font-family: HelveticaNeueMedium;
  font-size: 0.9rem;
  width: 95%;
  border: #000000 solid;
  border-width: 0.1rem;
  letter-spacing: 0.05rem;
  border-radius: 0;
  margin-left: 1rem;
  margin-bottom: 0.5rem;
}

#competitorTable td {
  border-right: 1px solid #808080;
}

#competitorTable tbody {
  background: #ffffff;
}

.search_dollar_img {
  font-size: 1.5rem;
  cursor: pointer;
}

.table-scroll {
  display: block;
  empty-cells: show;
  border-spacing: 0;
  border: 1px solid;
}

.table-scroll thead {
  background-color: #f1f1f1;
  position: relative;
  display: block;
  width: 100%;
}

.table-scroll tbody {
  display: block;
  position: relative;
  width: 100%;
  overflow-y: auto;
  border-top: 1px solid #000000;
}

.table-scroll tr {
  width: 100%;
  display: flex;
}

.table-scroll td,
.table-scroll th {
  flex-basis: 100%;
  flex-grow: 1;
  display: block;
  padding: 0.2rem;
  text-align: left;
}

.table-scroll.small-first-col td:first-child,
.table-scroll.small-first-col th:first-child {
  flex-basis: 100%;
  flex-grow: 1;
}

.competitorData {
  max-height: 10rem;
}

.no_competitors {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
}
.tier_icon {
  margin-left: 0.1rem;
  font-size: 0.5rem;
  color: #176db7;
  border: 1px solid #176db7;
  padding: 0;
}
.hnsInd{
  margin-left: 0.1rem;
  font-size: 0.7rem;
  color: #F79228;
  border: 1px solid #F79228;
  padding: 0;
}
.hnsInd.compact_indicator{
  font-size: 0.5rem;
}
.promo_indicator {
  color: #F79228;
}
.madeinusa_indicator{
  display: inline-block;
  height: 2rem;
  contain: content;
}
.miu-wrap{
  display: flex;
  align-items: center;
}
.discon-ind{
  color : #c8102e;
}
.hazmat-item {
  color: #6d6b6b;
}
.fom-ind {
  color: #176db7;
}
.wsl-ind {
  color: #4286f4;
}
.rebate-ind {
  color: #808080;
}
.seperator-color span {
  color: #000;
  margin-right: 0.5rem;
}

.new-item {
  color: #c8102e;
}
.core-item {
  color: #4f2b12;
}
#no_padding{
  padding-left: 0rem;
}
.color-red {
  color: #c8102e;
}

.greener-ind{
  color: #168c87;
}
.primary_wh_message {
  text-align: center;
  padding-bottom: 1rem;
}
.altrnateNbr{
  font-weight: bold;
  margin-left: 0.5rem;
}
.ui.down_table.posTable {
  text-align: center;
}

.altrnateNbr{
  font-weight: bold;
  margin-left: 0.5rem;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  #productDetailInfo {
    padding: 0.5rem;
  }
  #store_questions {
    float: none;
    margin-top: 0.5em;
  }
  .back_button{
    float: none;
    margin-top: 0.5em;
  }
  .link_button{
    float: none;
    margin: 0.5em;
  }
  #competitorDialog {
    width: 25rem;
    height: auto;
    background-color: #ffffff;
    position: fixed;
    opacity: 1;
    overflow: hidden;
    left: -3rem;
    top: 21%;
    z-index: 1;
    scroll-behavior: smooth;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  #prodDescription {
    width: 100%;
  }
  .pdpImage {
    margin-left: 16rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  #competitorDialog {
    width: 27rem;
    height: auto;
    background-color: #ffffff;
    position: fixed;
    opacity: 1;
    overflow: hidden;
    left: 9rem;
    top: 20%;
    z-index: 1;
    scroll-behavior: smooth;
  }
}

#dom-search {
  display: inline;
  background-color: #ffffff;
  border: 1px solid #000000;
  margin-right: 0.5rem;
}

#dom-search img {
  width: 0.8rem;
  height: 0.8rem;
  left: 35.4rem;
  bottom: 0.7rem;
}
#dom-search input {
  background: none;
  border: none;
}
#dom-search input:focus {
  outline: none;
}

.input-outline {
  border: 1px solid #000000;
}
.down_table.table tr td.store-name {
  width: 25%;
  align-items: top;
}
.down_table.table tr td.store-name,
.down_table.table tr td.store-name-aw {
  color: #c8102e;
  font-family: 'HelveticaNeueBold';
}

.down_table.table tr.bold-row > td,
.down_table.table tr > td.bold-col-header {
  font-family: 'HelveticaNeueBold';
}

.height-4 {
  height: 4.5rem;
}

.ui.grid.mt-0 {
  margin-top: 0;
}

.pt-2 {
  padding-top: 0.2rem;
}

.color-green {
  color: #388e3c;
}

.cplor-black {
  color: #000000;
}

.fl-r {
  float: right;
}

.down_table.table tr td.border-color-white {
  border-color: #ffffff;
}

.order-history-mobile {
  background-color: #ffffff;
  padding-top: 1rem;
}

.bg-white {
  background-color: #ffffff;
}

.w-16 {
  width: 16rem;
}

.indicator {
  display: inline-block;
  margin-right: 0.4rem;
  font-family: HelveticaNeueBold;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #selected-img {
    width: auto !important;
    height: auto !important;
    position: absolute;
    top: 50%;
    left:50%;
    transform: translate(-50%, -50%);
}
  .img-span{
    width: 21.7rem !important;
   display: inline-block !important;
  }
}
@media (max-width:1024px) {
  .alternativeItem {
    margin: 0;
  }
  .alternativeItem > * {
    font-weight: normal;
  }
}
.pdp-size{
  font-family: "HelveticaNeueBold";
  font-size: 1rem;  
}
.pdp-size img.img-mvp-size{
  max-width: 170px;
}
.pdp-size img.img-assort-size{
  max-width: 140px;
}