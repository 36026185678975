
.multistore-selection .right-align {
  text-align: right;
}
.segment_store {
  padding: 1rem 1rem;
}
.segment_store_selectAll {
  padding: 0 1rem;
}
.ms-sort{
  float:right;
}
.segment_store_list{
  padding: 3rem 1rem;
}
#multiStore .storeselection {
  font-size: 1.2rem;
  padding-left: 2rem;
}
.selectlabel {
  padding-right: 0.3rem;
}

.select_store_check {
  width: 50%;
  float: left;
  margin-bottom: 0.5rem;
  font-weight: bold;
}
.multistore-selection > .segment_store_list > .multoStoreView {
  padding-top: 0rem;
}
.store-select-all-span {
  cursor: pointer;
  color: #2b77f2;
  float: left;
  font-weight: bold;
}
@media screen and (max-width: 768px) {
  .select_store_check {
    width: 100%;
  }
}
@media print {
  .print-component {
    padding: 1rem;
    overflow: visible;
  	height: auto;
  }
  .ui.header .icon:only-child {
    display: none;
  }
}
