.fav-options {
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: flex-end;
}
.fav-options:after {
  content: ' ';
  display: inline-block;
  clear: both;
}
.fav-dept-div,
.fav-showby-div,
.fav-sortby-div,
.icon_align {
  display: inline-block;
  padding: 0 1rem;
}

.fav-dept-div {
  padding-left: 4rem;
}
.fav-right-options {
  display: inline;
  float: right;
}
#search_div_address{
  padding-bottom: 0rem;
}
.fav-header div {
  display: inline;
  padding-bottom: 1rem;
}
.fav-breadcrumb {
  margin-bottom: 0.5rem;
}
.fav-select-all-span {
  cursor: pointer;
  color: #2b77f2;
  float: right;
  font-weight: bold;
}
.icon_align i {
  cursor: pointer;
}
.content-wrap {
  padding: 0.5rem 1em;
  background: #f2f2f2;
}
.card_footer{
  margin-bottom: 0.0rem;
  margin-top: 0.00rem;
  align-items: inherit;
}
.card .content {
  padding-bottom: 2em !important
}

@media only screen and (max-width: 767px){
  .content-wrap {
    height: auto;
  }
  .card_footer{
    margin-bottom: 0.93rem;
    margin-top: 0.00rem;
  }
  .fav-footer{
    margin-bottom: 30px;
  }
  }
  .select-department {
    padding-right: 0.2rem;
    margin-right: 0.5rem;
    width: 10rem;
  }
.select-range {
  padding-right: 0.2rem;
  margin-right: 0.5rem;
}
.select-item {
  padding-right: 0.2rem;
  margin-right: 0.5rem;
}
.select-order {
  padding-right: 0.2rem;
  margin-right: 0.5rem;
}
.toggle-view {
  display: inline-flex;
  align-items: center;
}

.pagination-span {
  float: left;
}
.remove-select-product {
  float: right;
}

.vendor-span {
  margin-bottom: 0px;
}
.product-name {
  font-family: 'HelveticaNeueBold';
}
.card_wrap {
  padding-bottom: 0.2rem;
}

.image_lisitng_grid {
  padding-top: 1px;
}
.product-grid-segment {
  margin-left: 1rem;
}

.card_list_content_checkbox {
  display: block;
  float: right;
}
.content_checkbox_b1 {
  display: flex;
  float: right;
  align-items: center;
}
.favClassicTable tr td{
  word-break: break-all;
  }
.content_checkbox_remove {
  margin-right: 0.3rem;
  font-size: 0.8rem;
}
.pl_table_font {
  font-weight: bold;
}
.pl_table_body {
  font-size: 0.8rem;
}
.pl_product_model {
  width: 6rem;
  overflow-wrap: break-word;
  white-space: normal !important;
}
.pl_qty_box {
  width: 4rem;
}

.center-text {
  text-align: center;
}
.full-width {
  width: 100%;
}
.color-red {
  color: #ba1903;
}

.fav_card_item{
  height: 7.14rem;
  width: 7.14rem;
}
.favourite-More{
  margin-top: -2rem !important;
  margin-left: 0rem;
}
.favorite-hidepage{
  display: flex;
  justify-content: space-between;
}
.show_and_hide_imagesfavorite{
  margin-left: 2rem;
}
.gridfavourite{
  color: #ba1903;
  text-align:center;
  min-height: 1.2rem;
  margin-top: 1.5rem;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .fav-options {
    padding-right: 0rem;
    display: block;
  }
  .fav-dept-div,
  .fav-showby-div {
    padding-left: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .fav-showby-div {
    padding-top: 0rem;
  }
  .paginationMobile {
    position: relative;
    left: 3rem;
  }

  .PaginationMobileBottom {
    position: relative;
    left: 0rem;
    top: 0.25rem;
    bottom: 0.5rem;
  }
  .remove-select-product {
    margin-bottom: 0.5rem;
  }
  .favoritesDepartment {
    padding-right: o.5rem;
  }
  .fav-showby-div > span {
    padding-right: 0.5rem;
  }
  .fav-sortby-div > span {
    padding-right: 0.5rem;
  }
  .fav-dept-div > select,
  .fav-showby-div > select {
    width: 10rem;
    background-color: #ffffff;
  }
  .fav-sortby-div > select {
    background: #ffffff;
  }
  .fav-right-options {
    padding-top: 1rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .card_footer{
    margin-bottom: 0.93rem;
      margin-top: 0.00rem;
  }
  .fav-options {
    padding-right: 0rem;
    display: block;
  }
  .fav-sortby-div {
    padding-top: 0.5rem;
    margin-left: 3rem;
  }
  .fav-dept-div > select,
  .fav-showby-div > select {
    width: 10rem;
    background-color: #ffffff;
  }
  .fav-sortby-div > select {
    background: #ffffff;
  }
  .fav-sortby-div > .select-item {
    margin-left: 2rem;
  }
  .fav-right-options {
    padding-top: 3rem;
  }
}
@media only screen and (max-width: 1023px) {
  .favorite-hidepage {
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    margin-top:0.75rem;
  }
  .favorite-hidepage .favorite-lefttp {
    display: flex;
    justify-content: space-between;
    text-align: center;
  }
  .favorite-hidepage .ui.pagination.menu {
    margin-left: 0;
    left: 0;
    justify-content: center;
  }
}
/* for Ipad Pro and devices has min width of 1024px */
@media only screen and (min-width: 1024px) {
  .fav-options {
    display: block;
  }
  .fav-right-options {
    float: none;
  }
  .fav-right-options > button {
    float: right;
    margin-top: 0rem;
  }
  .fav-sort-div {
    float: right;
  }
}

@media only screen and (max-width: 1024px) {
  .favorite-hidepage {
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    margin-top:0.75rem;
  }
  .favorite-lefttp{
    display: flex;
    justify-content: space-between;
    text-align: center;
  }
  .favorite-hidepage .ui.pagination.menu {
    margin-left: 0;
    left: 0;
    justify-content: center;
  }
  .fav-options {
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    justify-content: flex-end;
    margin-left: -1rem;
  }
}
@media only screen and (max-width: 1024px) {
  .favList {
    position: relative;
  }
  .grid-header {
    z-index: 1;
  }
}
