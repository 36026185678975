@font-face {
  font-family: HelveticaNeue;
  src: url('../../fonts/HelveticaNeue Light.woff2');
  src: url("../../fonts/HelveticaNeue Light.eot"),
  url("../../fonts/HelveticaNeue Light.woff") format("woff");
 
}
@font-face {
  font-family: HelveticaNeueBold;
  src: url('../../fonts/Helvetica Neu Bold.woff2');
  src: url("../../fonts/Helvetica Neue Bold.eot"),
  url("../../fonts/Helvetica Neu Bold.woff") format("woff");
 
}
.bin {
  width: 2.25rem;
  padding-right: 0rem;
}

.op-error-span {
  color: #cb1e00;
  font-weight: bold;
  display: inline-block;
  margin-bottom: 2px;
  min-width: 51%;
  font-size: 0.95rem;
}
.op-error-span span {
  background-color: #f9e8a2;
}
.oredrpad-text  .order-message{
  font-size: 1.07142857rem;
  margin-left: 4px;
}
.op-show-hide-label {
  font-weight: bold;
}
.red_color{
  color: #ff0000;
}
.page-header.oredrpad-text{
  margin-bottom: 2rem;
}
.op-input {
  border: 1px solid #000000;
  height: 2rem;
  width: 5rem;
  background-color: #ffffff;
}

.upload-instructions-div {
  width: 43rem;
  font-family: HelveticaNeue;
  font-weight: bold;
}
.upload-instructions-div p {
  margin-bottom: 0;
}
.upload-instructions-div ol {
  list-style-position: inside;
  padding-left: 0;
}
.cipherBtn {
  margin-bottom: 0.5rem !important;
}
.upload-header {
  font-family: HelveticaNeueBold;
}
.stockMsg {
  font-family: HelveticaNeueBold;
  text-align: left;
  font-size: 0.95rem;
}
.order-pad {
  width: 100%;
  margin-top: 20px;
  min-width: 990px;
}
.order-pad-header {
  font-family: HelveticaNeueBold;
}
#errorItem {
  background: #f5b4a9eb;
}
#errorItem.splitGrid {
  border-top:2px solid #ccc;
}
#errorItem + .gridBroderStyle {
  background: #f5b4a9eb;
  border-bottom:3px solid #ccc;
}
.order-pad .image_lisitng {
  display: inline-block !important;
  vertical-align: bottom;
  max-height: 100%;
  max-width: 100%;
}
.inputQuickViewWrap {
  display: inline-block;
}
.itemDetailWrap {
  display: flex;
}
.itemDetailWrap > a {
  width: 3.5rem;
  height: 3.5rem;
  margin-right: 0.5rem;
  text-align: center;
}
.mg-l-1 {
  margin-left: 27px;
}
.order-pad .delete-icon {
  height: 1.25rem;
  cursor: pointer;
  width: 1.25rem;
  margin-right: 10px;
}
.order-pad .op-clear-label {
  padding-top: 0.5rem;
}
.order-pad .op-clear-label {
  padding-top: 0.5rem;
}

.order-pad-container {
  padding: 0.5rem 1em;
  margin-top: 0;
  background-color: #f2f2f2;
  overflow: hidden;
  overflow-x: auto;
}
.order-pad-container .ui.grid{
  position: relative;

}
.order-pad-container .referalText {
  position: absolute;
  font-weight: bold;
  top: -0.5rem;
}
.order-pad-container .splitGrid {
  background-color: rgb(254 255 218);
  border-top: 4px solid #ccc;
}
.order-pad-container .gridBroderStyle {
  background-color: rgb(254 255 218);  
  border-bottom: 1px solid #ccc;
  padding-top: 1rem;

}
.order-pad-container .gridBroderStyle > div {
  padding-top: 1.5rem !important;
}
.mr-b-3 {
  margin-bottom: 3rem;
}
.baseline-flex {
  display: flex;
  align-items: baseline;
}
.bold-font {
  font-weight: bold;
}

.orderPadPo {
  text-align: right;
  margin-top: -2rem;
  transform: translateY(-100%);
  z-index: -1;
  font-family: HelveticaNeueBold;
  padding-right: 3rem;
}
.order-pad .op-labeled-input {
  width: 3rem;
}
.order-pad .op-labeled-input input  {
  padding: 0;
  padding-right: 0.25rem;
}
.orderPadTier {
  font-size: 0.8rem;
  margin-left: 0.25rem;
  padding: 0.1rem;
}
/* Mobile styles goes here */
@media only screen and (max-width: 767px) and (min-width: 320px) {
  .order-pad .ui.grid .header-message {
    display: none;
  }
  .order-pad
    .ui.labeled.input:not([class*='corner labeled'])
    .label:first-child {
    position: absolute;
  }
  .order-pad .delete-icon {
    position: absolute;
  }
  .order-pad .op-labeled-input .ui.label {
    height: 1.5rem;
  }

  .order-pad .op-input {
    height: 1.5rem;
    width: 4rem;
  }
  .op-labeled-input input {
    height: 1.5rem;
    padding: 0 0 0 2rem;
  }
  .order-pad .op-input > input,
  .order-pad .ui.input > input {
    padding: 0;
  }
  .order-pad .ui.op-labeled-input {
    width: 5rem
  }
  .order-pad .ui.op-labeled-input > input {
    padding-left: 2rem;
  }
  .order-pad .ui.labeled.input > .label {
    font-size: 0.9rem;
  }
  .item-number-box {
    left: 2rem;
  }
  .order-pad .ui.grid > .column.error-message {
    padding-top: 0;
    padding-bottom: 0;
    margin-left: 2rem;
  }
}

@media only screen and(min-width:768px) and (max-width:1024px) {
  .ui.column.grid>[class*="two wide tablet"].column, 
  .ui.grid>.column.row>[class*="two wide tablet"].column, 
  .ui.grid>.row>[class*="two wide tablet"].column, .ui.grid>[class*="two wide tablet"].column{
    width: 18.5% !important;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .op-input > input{
    width:inherit;
  }
  .op-labeled-input > input{
    width: inherit;
  }
}
