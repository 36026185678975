#camera, #camera--view, #camera--sensor, #camera--output{
  position: fixed;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
#camera--view, #camera--sensor, #camera--output{
  transform: scaleX(1);
  filter: FlipH;
}
#response{
  color: black;
  position: fixed;
  bottom: 5rem;
  left: calc(2%);
}
#camera--trigger{
  width: 200px;
  background-color: black;
  color: white;
  font-size: 16px;
  border-radius: 30px;
  border: none;
  padding: 15px 20px;
  text-align: center;
  box-shadow: 0 5px 10px 0 rgba(0,0,0,0.2);
  position: fixed;
  bottom: 30px;
  left: calc(25% - 100px);
}
#camera--switch{
  width: 100px;
  background-color: black;
  color: white;
  font-size: 16px;
  border-radius: 30px;
  border: none;
  padding: 15px 20px;
  text-align: center;
  box-shadow: 0 5px 10px 0 rgba(0,0,0,0.2);
  position: fixed;
  bottom: 30px;
  left: calc(70%);
}
.taken{
  height: 100px!important;
  width: 100px!important;
  transition: all 0.5s ease-in;
  border: solid 3px white;
  box-shadow: 0 5px 10px 0 rgba(0,0,0,0.2);
  top: 20px;
  right: 20px;
  z-index: 2;
}
.loaderDisplay{
position: absolute;
top:1.5rem;
right:50%;
}
.errorScan{
  position: absolute;
  top:20px;
  color:#cb0c0c;
}
.camera-option{
  color:#ffffff !important;
}
#camera-menu:after{

background: #1a1c21e6 !important;
}
.header_user_icons .scanLink {
color: #000000 ;
}
.react-html5-camera-photo  video{
height: 35rem;
width: 100%;
}
.scannerError{
text-align: center;
}
.inner-circle {
left: 50%;
position: absolute;
top: 2.375rem;
height: 2.75rem;
width: 2.75rem;
background: #fff;
margin: -1.375rem 0 0 -1.375rem;
z-index: 2;
border-radius: 50%;
}
.outer-circle {
left: -37px;
position: absolute;
height: 4.6875rem;
width: 4.6875rem;
background-color: hsla(0,0%,100%,.4);
z-index: 1;
border-radius: 50%;
}
.container-circles {
position: absolute;
left: 50%;
bottom: 7.5rem;
}
.scanner-div{
margin: auto;
width:62.5rem;
position:relative;
}
.camerawidth{
position: relative;

}
#camera-options-dropdown{
position: absolute;
color: #ffffff;
left:70%;
bottom:2rem;
font-size:2rem;
}
.cameraBlock{
display: none;
}
.restore-circle {
left: 15%;
bottom:22px;
height: 44px;
position: absolute;
width: 44px;
background: #fff9f9;
border-radius: 50%;
border-color: transparent #641b1b;
}
.scannerWrapper {
display: flex;
width: 100%;
text-align: center;
justify-content: flex-end;
}
.scannerWrapper #video {
width: 32rem;
height: 32rem;
margin: auto;
}
.scanBtn {
  text-align: right;
}
.scanBtn button.ui {
  margin: 10px 5px;
}
.scannerWrapper button.ui {
margin-top: 1rem;
margin-right: 2%;
height: 2.5rem;
}

#sourceSelect {
margin: auto auto 10px;
display: inherit;
}
.scannedWHproduct #icon_align {
 text-align: right;
}

@media only screen and (min-width: 601px) and (max-width: 768px)  {
.container-circles {
    position: absolute;
    left: 50%;
    bottom: 9.125rem;
    
}
.scanner-div{
    width:100%;
}
div#camera-menu {
    right: -9.2em;
}
.react-html5-camera-photo video {
  width:100% !important;
  height:auto!important;
}
.restore-circle {
left: 15%;
bottom: 22px;
}
}
@media only screen and  (max-width: 600px)  {
.container-circles {
    position: absolute;
    left: 50%;
    bottom: 9.125rem;
    
}
.scanner-div{
    width:100%;
}
div#camera-menu {
    right: -9.2em;
}
.react-html5-camera-photo video {
  width: 23rem !important;
  height: auto!important;
}
.restore-circle {
left: 15%;
bottom: 22px;
}
}

@media (max-width:480px) {
  .scannerWrapper {
    justify-content: center;
    position: relative;
    padding-bottom: 100%;
    margin: auto;
    max-width: 450px;
  }
  .scannerWrapper #video {
    width: 100%;
    height: 100%;
    margin: auto;
    position: absolute;
  }
}