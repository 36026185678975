.allOrdersTable {
    margin-top: 1rem;
  }
  
  #allOrdersTable {
    margin-left: 1rem;
    font-family: HelveticaNeueBold;
  }
  
  #font-size-orders {
    font-size: 0.9rem !important;
    font-family: HelveticaNeueBold;
  }
  
  .allOrderFilters {
    width: max-content;
  }
  .orderstatusMargin {
    margin-top: 2rem !important;
  }
  
  .allOdrHeader {
    background-color: rgb(242, 242, 242) !important;
    color: #000000 !important;
    text-align: center !important;
    text-decoration-color: #a3a3a0;
    padding: 0.4rem 0.2rem !important;
  }
  
  .OderItemHeader {
    background-color: rgb(242, 242, 242) !important;
    color: #000000 !important;
    text-align: left !important;
    text-decoration-color: #a3a3a0;
    padding: 0.4rem 0.2rem !important;
  }
  
  #back_button {
    float:right;
    cursor: pointer;
    color: #4183c4;
    font-weight: bold;
    text-decoration: underline;
  }
  
  #item_field {
    float:center;
    cursor: pointer;
    color: #4183c4;
    font-weight: bold;
    text-decoration: underline;
  }
  
  #no_border {
    border: none;
    width: 8rem;
  }
  
  #title_width {
    width: 20rem;
    border: none;
  }
  
  .order_details {
    margin-left: 0.5rem;
  }
  
  .order_details_tracking {
    margin-left: 0.5rem;
    text-decoration: underline;
  }
  
  .mouseHover {
    background-color : #4183c4;
    list-style-type: none; 
    border-radius : 5px;
    margin-top : 19px;
    position: absolute;
    z-index: 2;
  }
  
  .mouseHoverText {
    color: rgb(242, 242, 242);
    margin-left: -37px;
    margin-right: 1px;
    padding: 2px;
  }
  
  #field_width {
    border: none;
    width: 9rem;
  }
  
  #field_width_edit {
    border: none;
    width: 10rem;
  }
  
  #field_Desc_width {
    border: none;
    width: 18rem;
    white-space : pre!important;
  }
  
  .created_by {
    font-family: HelveticaNeue;
    font-size: 0.8rem;
  }
  
  .order_count {
    float: right;
    font-family: HelveticaNeueBold;
  }
  
  .excel_left {
    margin-left: 4rem;
  }
  #order-card-table {
    font-family: HelveticaNeue;
    font-weight: bold;
    width: 100%;
  }
  .order-card-field {
    font-family: HelveticaNeueBold;
    padding-left: 1rem;
  }
  .all-orders-card {
    background-color: #ffffff;
    padding: 1rem;
    margin-top: 0.6rem;
    border: 1px solid #000000;
  }
  .view-details-link {
    display: block;
    font-family: HelveticaNeue;
    font-weight: bold;
    color: #176db7;
    text-decoration: underline;
    cursor: pointer;
  }
  .tracking-data {
    font-size: 0.8rem;
  }
  
  .itemDetails {
    margin-left: 0rem;
    display: flex;
    flex-wrap: wrap;
  }
  
  .underline {
    text-decoration: underline;
  }
  
  .font-style {
    font-family: HelveticaNeueBold;
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
  }
  
  .orderImage {
    width: 2rem;
    height: 2rem;
    margin-left: 1rem;
  }
  #hideShowImages {
    cursor: pointer;
    color: #4183c4;
    font-weight: bold;
    text-decoration: underline;
    margin-right: 4rem;
  }
  
  #order_sts_dropdown {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  
  #search_order_status {
    width: 9.5rem;
    /* height: 0.8rem; */
    border:1px solid black;
    border-radius: 0rem;
    padding-left: 0.625rem;
    border-color: #a9a9a9 !important;
    padding-right: 2rem;
  }
  
  .sort_by {
    float: right;
    display: flex;
    align-items: baseline;
  }
  
  #remove_border {
    border-left: none;
    text-align:center;
  }
  
  .padding th, td { 
    padding :0.5rem 0.2rem !important;
  }
  
  .hide_created_by {
    display: none;
  }
  .OrderTotalitem{
    vertical-align: top;
  }
  .paginationAllorders{
    margin-left: 1rem;
  }
  .addressbookSize{
    vertical-align: top;
    margin-left: 2rem;
  }
  .search-allorder{
    margin-top: 1rem !important;
  }
  .order-pagination{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .pagination-padding{
    padding-top : 1rem;
  }
  .filterorderstatus{
    margin-top: 2rem;
    flex: 0 0 20%;
  }
  .portrait-pagination {
    display: inline-table;
    text-align: center;
    margin: 0 auto;
    width: 80%;
  }
  #ds_orderStatusTable {
    flex-grow: 1;
  }
  .dsOrderDetailLg {
    justify-content: center;
  }
  .ds_orderDetails p {
    margin: 0 0.5rem;
  }
  .ds_print#printicon.icon {
    font-size: 1.5em;
  }
  .ds_allorderCount #back_button {
    float:none;
  }
  .dsOrderDetailLg {
    margin-top: 0 !important;
  }
  .dsOrderDetailLg .stickyTableOrderLevelDetails th {
    top: 14rem;
    background: #333333 !important;
    color: #fff !important;
    line-height: 1.1;
  }
  #ds_orderStatusTable .stickyTableEditOrder td {
    min-height: 2rem;
    border-bottom: 1px solid #000;
  }
  #ds_orderStatusTable .fa-eye {
    font-size: 1.2em;
    cursor: pointer;
  }
  .ds_os_msg {
    background-color: #ff0;
    color: #db2828;
    display: inline-block;
    padding: 0 0.25rem;
    margin-top: 0.5rem !important;
  }
  .all_orderTable {
    margin-top: 0 !important
  }
  .allOrderBtn {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 0.75rem;
  }
  .allOrderBtn > div {
    min-width: 23rem;
  }
  .allOrderBtn > span {
    flex-grow: 1;
    text-align: center;
    color: #db2828;
    width: 70%;
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    .order-card-field {
      width: 50%;
    }
    .orderImage {
      width: 5rem;
      height: 5rem;
      margin-left: 15rem;
    }
    .itemDetails {
      margin-left: 0.5rem;
    }
    .allOrderstablemobile {
      margin-top: 4rem;
    }
    .sort_by {
      position: relative;
      right: 10rem;
      margin-top: 1rem;
    }
  }
  
  @media screen and (max-width: 767px) {
    .paginationAllorders{
      margin-left: 2rem;
    }
    .addressbookSize{
      margin-left: 0rem;
    }
    .excelHide {
      display: none;
    }
    #hideShowImages {
      display: none;
    }
    #field_width {
      width: 14rem;
    }
    .allOrderstablemobile {
      margin-top: 0rem;
      float: left;
      width: -webkit-fill-available;
    }
    .itemDetails {
      position: relative;
      top: 1rem;
    }
    .itemDetailsMobile {
      position: relative;
      left: 0;
      top: 0;
      min-width: 50%;
    }
    .itemDetailsMobileInvoice {
      position: relative;
      left: 0;
      top: 0;
      min-width: 50%;
    }
    .allorderCount {
      margin-top: 2rem;
    }
    .orderImage {
      width: 5rem;
      height: 5rem;
      margin-left: 7rem;
    }
  
    .allOrdersTableItem {
      margin-top: 2.5rem;
    }
  
    .sort_by {
      margin-top: 1rem;
    }
  
    .order_count {
      margin-top: 0.5rem;
    }
    .image-option {
      display: flex;
      justify-content: space-between;
    }
    .image-option #hideShowImages {
      display: block;
    }
  }
  
  @media only screen and (min-width: 320px) and (max-width: 767px) {
    .displayFlex.allOrdersTable {
      display: block;
    }
    .allOrderstablemobile .portrait-pagination {
      width: 100%;
    }
  }
  @media only screen and (max-width: 480px) {
    .mobile_sort > select {
      margin-bottom: 0.5rem;
    }
  
  }
  
  @media screen and (min-width:1025px) {
    .stickyTable th {
      position: -webkit-sticky;
      position: sticky;
      top: 5.7rem;
      background: #333333 !important;
      color: #fff !important;
      line-height: 1.1;
    }
    .stickyTableEditOrder th {
      position: -webkit-sticky;
      position: sticky;
      top: 13.5rem;
      background: #333333 !important;
      color: #fff !important;
      line-height: 1.1;
    }
    .stickyTableOrderLevelDetails th {
      position: -webkit-sticky;
      position: sticky;
      top: 18rem;
      padding-top:20px;    
      line-height: 1.1;
      }
  }
  
  .hidden-sim{
    visibility: hidden;
  }
  .includeAllOrderItems{
    margin-top: 0.5rem;
    margin-right: 1.5rem;
  }
  .includeAllOrderItems label{
    font-family: HelveticaNeueBold;
    margin-left: 5px;
    margin-top: 0;
  }
 
/* Navbar css */

.TabItems .nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;  
}

.TabItems .nav .nav-link {
  font-family: HelveticaNeueBold ;
  background: #e0e1e2 !important;
  color: #000 !important;
  min-width:150px;
  outline: none;    
  display: flex;
  padding:7px 10px 7px 20px;
  justify-content: space-between;
  align-items: center;
}

.TabItems .nav-link:hover, .TabItems .nav-link:focus {
  text-decoration: none;
}

.TabItems .nav-link.disabled {
  color: #6c757d;
}

.TabItems .nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.TabItems .nav-tabs .nav-item {
  margin-bottom: -1px;
}

.TabItems .nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.TabItems .nav-tabs .nav-link:hover,.TabItems .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.TabItems .nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.TabItems .nav-tabs .nav-link.active,
.TabItems .nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}


.TabItems .tab-content > .tab-pane {
  display: none;
}

.TabItems .tab-content > .active {
  display: block;
}

.TabItems .nav-link.active{
  background: #db2828 !important;
  color: #fff !important;
}
.TabItems .nav-link .itemCounts{
  border-radius: 45%;
  padding: 0px 6px;
  margin-left:10px;
  background-color: #fff;
  color: #000;
} 
.TabItems .tab-content{
  padding:10px;
  border:solid 1px #a9a9a9;
  min-width: 100%;
}
.TabItems .allOdrHeader,.TabItems .OderItemHeader{
  background-color: #000 !important;
  color:#fff !important;
}
.TabItems .tab-content .contentTitle{
   font-family: HelveticaNeueBold;
   font-size: 20px;
}
.TabItems .orderPanel{
  width:100%;  
}
.orderPanel .panel-header{
  background-color: #494a4b;
  color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 3px 10px;
  align-items: center;
  font-size: 16px;
  font-family: HelveticaNeueBold;
  margin-top:10px;
}
.orderPanel .panel-header h2{    
  font-size: 16px;
  padding: 3px 0;
  font-weight: bold;
  margin-bottom: 0;
  margin-top:0
}
.orderPanel .tabIcons{
  font-size: 30px;
  line-height: 20px;
  margin-top: -10px;
}
.orderPanel .panel-content{
  padding:10px;
  display: grid;
  grid-template-columns: auto auto;    
  border:solid 1px #000; 
}
.orderPanel .panel-content .leftPanelContent{
  font-size: 14px;
  font-family: HelveticaNeue;
}
.orderPanel .panel-content .leftPanelContent p{
  margin-bottom: 3px;
}

.orderLinkTab{
  margin-top: 20px;
}
.orderLinkTab a{
  font-family: HelveticaNeueBold ;
  background: #e0e1e2 !important;
  color: #000 !important;
  min-width:150px;
  display: inline-block;
  outline: none; 
  padding:10px 20px;
  text-align: center;
}
.orderLinkTab a.active{  
  background: #db2828 !important;
  color: #fff !important;
}