@font-face {
    font-family: arialBold;
    src: url('../../fonts/G_ari_bd.TTF');
  }
.tvnwh-footer {
    display: flex;
    flex-direction: column;
    color: #fff;
}
.tvnwh-footer p {
    font-family: Arial, Helvetica, sans-serif;
}
.tvnwh-footer a {
    font-family: Arial, Helvetica, sans-serif;
    color: #fff;
}
.tvnwh-footer a:hover {
    color: #C8102E;
}
.tvnwh-footer h2 {
    font-family: arialBold;
    white-space: nowrap;
}
.footerColWrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 3rem;
}
.footerColWrapper figure {
    margin: 0;
}
.footerColWrapper .footerImgBlock {
    max-width: 25rem;
    border-right: 1px solid #fff;
    padding-right: 6rem;
}
.footerColWrapper figure img {
    max-width: 100%;
    height: auto !important;
}
.footerLinkCol {
    display: flex;
    justify-content: space-around;
    gap: 0.5rem;
}
.footerBottom {
    text-align: center;
}
.footerLinkCol >div:nth-of-type(2) {
 padding-top: 1.5rem;
}
.offlineFooter {
    background-color: #222;
    position: relative;
    height: 15rem;
    
}
@media(max-width:767px) {
    .footerColWrapper .footerImgBlock {
        max-width: 90%;
        border: 0;
        flex-basis: 100% !important;
        padding: 0;
        margin-bottom: 1rem;
    }
    .footerLinkCol {
        flex-wrap: wrap;
    flex-direction: column;
    }
    .footerLinkCol >div:nth-of-type(2) {
        padding-top: 0rem;
    }
    .footerLinkCol .wp-block-group p {
        margin-bottom: 0.2rem;
        text-decoration: underline;
        margin-right: 0.25rem;
    }
    .footerLinkCol .wp-block-group  {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
             -ms-flex-wrap: wrap;
                 flex-wrap: wrap;
             -webkit-box-pack: start;
                 -ms-flex-pack: start;
                     justify-content: flex-start;
             width: 100%;
             gap: 8px 16px;
             gap: 0.5rem 1rem;
        
    }
    .tvnwh-footer h2 {
        font-family: arialBold;
        width: 100%;
        margin-bottom: 0.3rem;
    }
    .footerColWrapper .wp-block-column:nth-of-type(2) {
        flex-basis: 100% !important;
    }
}