.action-cell,.link-style {
    color: #4183c4;
    cursor: pointer;
  }
  .remove-all {
    position: relative;
    bottom: -15px;
    right: 10px;
  }
  .fl {
    float: left;
  }
  .fr {
    float: right;
  }
  .mr-5 {
    margin-right: 10px;
  }
  .white-bg {
    background-color: #ffffff;
    padding-bottom: 10px;
  }
  .mt-5 {
    margin-top: 10px;
  }
  .text-under-line {
    text-decoration: underline;
  }
  .clr {
    clear: both;
  }
  .ui.input > input.search-input {
    width: 9.5rem;
    border-color: #a9a9a9 !important;
    padding: 0;
    height: 1.5rem;
  }
  .ship-later-section .ui.grid {
    margin-bottom: 0;
  }
  .ship-later-section {
    font-family: HelveticaNeueBold;
  }
  .ship-later-grid.collapse .grid {
    width: calc(100% - 185px);
    }
  .ship-later-grid .grid {
    width: calc(100% - 34px);
    margin-left: 10px;
  }
  .shiplater-products {
    width: 100%;
    border-collapse: collapse;
  }
  .shiplater-products input[type='checkbox'], .btn-remove-selected {
    cursor: pointer;
  }
  
  .shiplater-products thead {
    background-color: #efefef;
    font: HelveticaNeueBold;
    text-align: center;
  }
  .shiplater-products td,
  .shiplater-products th {
    text-align: center;
    border: none;
    max-width: 20rem;
    padding: 0.2rem 0.3rem !important;
    font-size: 0.9rem;
  }
  .shiplater-products th {
    font-family: HelveticaNeueBold;
  }
  .shiplater-products td {
    vertical-align: top;
  }
  .even-row,
  .product-row {
    background-color: #ffffff;
  }
  .even-row td{
    border-bottom: 0.1rem solid #a2a5a7b3;
  }
  .ship-later-grid .assortmentFilters {
    width: 12.5rem;
  }
  .search-label {
    font-family: HelveticaNeue;
    font-weight: bold;
  }
  .total_amt_shplaters {
    margin-left: 0.5rem;
    color: #ff0000;
  }
  .btn-remove-selected {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }
  .btn-remove-selected:disabled {
    opacity: 0.5;
    cursor:not-allowed;
  }
  table.shiplater-products thead tr th:nth-of-type(4) {
    min-width: 7rem;
  }
  .shipLaterMbChkBox {
    display: flex;
    align-items: center;
  }
  .shipLaterMbChkBox label {
    margin-right: 0.5rem;
  }
  @media only screen and (max-width: 760px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
  
    .ui.stackable.grid>.wide.column.shiplaterSearch{
      margin: 0px !important;
      padding: 0px !important;
    }
    .ui.stackable.grid>.wide.column.shiplaterSort{
      margin-top: 10px !important;
      padding: 0px !important;
    }
    .remove-all {
      position: relative;
      bottom: 0;
      right: 0; 
    }
    .ship-later-grid.collapse .grid {
      width: calc(100% - 0px);
    }
    .shiplaterfilters {
      width: 100%;
    }
    .ship-later-grid .assortmentFilters {
      width: 100%;
    }
    .ship-later-section .data-grid td:nth-of-type(1):before {
      content: ' ';
    }
    .ship-later-section .data-grid td:nth-of-type(2):before {
      content: 'Item #';
    }
    .ship-later-section .data-grid td:nth-of-type(3):before {
      content: 'Model #';
    }
    .ship-later-section .data-grid td:nth-of-type(4):before {
      content: 'PO #';
    }
    .ship-later-section .data-grid td:nth-of-type(5):before {
      content: 'Invoice #';
    }
    .ship-later-section .data-grid td:nth-of-type(6):before {
      content: 'Item Description';
    }
    .ship-later-section .data-grid td:nth-of-type(7):before {
      content: 'Pack';
    }
    .ship-later-section .data-grid td:nth-of-type(8):before {
      content: 'RDC';
    }
    .ship-later-section .data-grid td:nth-of-type(9):before {
      content: 'Qty';
    }
    .ship-later-section .data-grid td:nth-of-type(10):before {
      content: 'Cost	';
    }
    .ship-later-section .data-grid td:nth-of-type(11):before {
      content: 'Ext. Cost	';
      line-height: 1;
    }
    .ship-later-section .data-grid td:nth-of-type(12):before {
      content: 'Status	';
      line-height: 1;
    }
    .ship-later-section .data-grid td:nth-of-type(13):before {
      content: 'Ship Later Age ';
      line-height: 1;
    }
    .ship-later-section .data-grid td {
      line-height: 2.5;
    }
    .ship-later-section .data-grid td:before {
      white-space: normal;
    }
    .search-container {
      margin-top: 1rem;
    }
  }
  @media only screen and (min-width: 1025px) {
    @supports(position: sticky) {
      .shiplater-products th {
        position: sticky;
        top:10rem;
        padding:0.5rem 0.3rem;
        background: rgb(49 49 49);
        color: #fff;
        font-size: 0.9rem;
        font-weight: 700;
        line-height: 1.1;
      }
    }
    .shiplater-products thead > tr {
      background: #313131;
    }
    .ship-later-section .ui.grid {
      position: sticky;
      top: 5.7rem;
      background: #f2f2f2;
    }
    .ship-later-grid {
      display: flex;
    }
    .shiplaterHeader {
      min-width: 7rem;
    }
  }