.error_404_page{
    position: absolute;
    left: 30%;
    top: 30%;
}

.text_center{
    text-align: center;
}
.oops-img{
    height:15rem;
    font-size: 9rem;
    color: #6b6464;
}

@media only screen and (max-width: 767px) {
 .oops-img{
     height: 13rem;
     font-size: 6rem;
 }

}
@media screen and (max-width: 600px) {
    .oops-img{
        height: 11rem;
        font-size: 5rem;
    }
}