.multihead-action{
    display: flex;
    justify-content: space-between;
}
.multistore-head span {
    font-size: 12px;
    font-weight: 100;
}
.item-spec .product-description{
    font-weight: 700;
    font-size: 13px;
    margin-left: 2rem;
}
.multistore-selection {
    float: left;
    width: 100%;
}
.msselect-all{
    display: flex;
    margin-bottom: 2rem;
}
.multistore-desc{
    font-size: 12px;
    font-weight: 200;
}
.multistore-content{
    border-top: 1px solid;
}
.multistore-content  .multistore-selection .field-store {
    font-size: 12px;
    font-weight: bold;
    float:left;
    width:100%;
}
.field-store label {
    margin-right: 1rem;
}
.field-store input[type="text"]{
    width: 25%;
}
.add-to-cart-modal .multistore-content .multistore-desc {
    height: 20rem;
    overflow-y: scroll;
}
.quantity-store {
    float: left;
    width: 45%;
    text-align: left;
    margin-left: 1rem;
}
.rs-qtylist  input[type="text"],.ms-qtylist  input[type="text"]  {
    width: 50%;
}
.field-store .ms-qtylist label {
    margin:0rem;
}
.override-store {
    float:left;
    width:50%;
}
.rs-qtylist,.ms-qtylist{
    float:left;
    width:50%;
    margin-bottom: 0.6rem;
}

.ms-qtylist_DS{
    float:left;
    width:100%;
    margin-bottom: 0.6rem;
}
    /* display: flex;
    align-items: center;
    justify-content: space-between;
} */

.add-to-cart-modal #multistore-action{
    background: #ffffff;
}
.multistore-selection {
    margin-bottom: 1rem;
}
.multistore-selection input[type="checkbox"] {
    margin-top: 0.2rem;
    margin-right: 0.5rem;
}
.store-details {
    float: left;
}
.store-details p {
    margin-left: 0.4rem;
}
.multihead-action span a{
    cursor: pointer;
}
.inline-block {
    display: inline-block;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .ui.modal.add-to-cart-modal.multistore-modal {
        width: 30rem!important;
        margin-top: 0rem;
    }
}

@media only screen and (max-width: 767px) {
    .quantity-store {
        width: 45%;
    }
    .ui.modal.add-to-cart-modal.multistore-modal {
        width: 24rem!important;
    }
    .multistore-desc label{
        font-size: 11px;
    }
    .store-details{
        font-size: 12px;
    }
}

