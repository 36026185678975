@font-face {
  font-family: HelveticaNeue;
  src: url('../../fonts/HelveticaNeue Light.woff2'); 
  src: url("../../fonts/HelveticaNeue Light.eot"),
  url("../../fonts/HelveticaNeue Light.woff") format("woff");
}

@font-face {
  font-family: HelveticaNeueBold;
  src: url('../../fonts/Helvetica Neu Bold.woff2'); 
  src: url("../../fonts/Helvetica Neue Bold.eot"),
  url("../../fonts/Helvetica Neu Bold.woff") format("woff");
}

.wh-First_level_details{
font-size: 1rem;
line-height: 1.78rem;
}

.wh-card{
width: 50%;
display: inline-block;
}
.wh-header{
  background-color: #000000 !important;
  color:#ffffff !important;
  text-align: center !important;
  text-decoration: underline;
  text-decoration-color: #a3a3a0;
  padding:0.3rem 0.2rem !important;

}
.wh-header.t-left{
  text-align: left !important;
}
.wh-row{
  text-align: center;
}

.AddCartBottom {
  margin-bottom: 2rem;
  float: right;
  padding-right: 5.8rem;
}

.wh-row>td{
  border-left:none !important;
  border-bottom: 0.1rem solid #222426 !important;
}

.wh-img-div{
  width: 6.5rem;
  margin-left: 3rem;
  position: absolute;
}
.wh-img-div div{
  display: inline-flex;
  padding:0rem;
  align-items: center;
  height:7.14rem;
}
.all-wh-table{
  font-size: 0.8rem !important;
  margin-bottom: 1rem !important;
}

#warehouse_cart_btn{
  margin-bottom: 1rem;
}

.card-field{
  background-color:#d6d8db;
  color:#000000;
  /* font-weight:bold; */
  width:32%;
}
.card-value {
  font-weight: bold;
  background: #f2f2f2 ;
}
.rdc-card-table{
  display: table;
  width:100%;
  margin-bottom:0.5rem;
}
.addCart {
  padding-right: 6rem;
  float: right;
}

#warehouseTable {
  padding-right: 6rem;
  font-weight: bold;
}

.checkAllwarehouse {
  padding-bottom: 0.5rem;
}

#itemDetailRow {
  font-weight: bold;
  padding: 0rem;
}

.maxQty {
  display: inline-block;
  font-size: 0.75rem;
}

#image_style{
  margin-top: 1.5rem;
  width: 6rem;
  height:7rem;
}
#itemDetailRow .alternativeItem {
  margin-left: 8.5rem;
}
.etaQtyDate {
  margin-bottom: 0;
  text-align: left;
}
@media screen and (max-width: 600px) {
  .wh-img-div div{
    display: inline-block !important;
  }
  .wh-First_level_details{
    padding-left: 11rem;
  }
  .wh-First_level_details ,.dimensions-div{
    width: inherit;
  }
  .wh-tier-table #Quicktier_table {
    margin-left: 2.25rem;
  }
  .rdc-card-table td{
    text-align: left;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  #whImg-div {
    width: 9.25% !important;
  }
  #FirstLevelDetails {
    width: 42.75% !important;
  }
  #tablePricing {
    width:61% !important;
    left: 9rem;
    top: 1rem;
  }
  #warehouseTable {
    padding-right: 0rem;
  }
  .addCart {
    padding-right: 0rem;
    width: 10rem;
  }
  .AddCartBottom {
    width: 10rem;
    padding-right: 0rem;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .wh-img-div div{
    display: block;
  }
}
@media (max-width:1024px) {
  #itemDetailRow .alternativeItem {
    text-align: center;
    width: 100%;
    margin-left: 0;
  }
} 