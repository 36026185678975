  .left-nav,.right-nav{
    /* position: absolute; */
    font-size: 5em;
    cursor: pointer;
    /* top: 50%; */
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
  }
  .thumbnail-img{
    float:left;
    max-height: 5rem;
    padding:0.3rem;
    max-width: 5rem;
    object-fit: contain;
  }
  .thumbnail-img.current{
    border:0.1rem solid rgb(209, 22, 22);
  }
  .cursor{
    cursor:pointer;
  }
  .image-wrap,.image-wrap-modal{
      text-align: center;
  }
  .image-wrap-modal.image-wrap-modal-tile{
    height: 400px;
  }
  #selected-img{
    /* width: -webkit-fill-available;
    height: auto; */
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
  }
.d-inline-center{
  display: inline-flex !important;
  justify-content: center !important;
  align-items: center;
}
.zoom-img{
  cursor:pointer;
  position: absolute;
  top:0.5rem;
  right: -1.5rem;
  height: 1.5rem;
  width: auto;
}
.image-gallery-wrap{
  background-color: #ffffff;
}
.image-wrap{
  /* width: 21.7rem; */
  height: 24rem;
}
.img-span{
  position: relative;
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center
}
.image-modal{
  display: block;
  position:fixed;
  z-index:5;
  left:0;
  top:78px;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color:rgba(0,0,0,0.5);
  /***/
  padding-top:5%;
}
.close{
  color: white;
  position: absolute;
  top: 1rem;
  right: 0.5rem;
  font-size: 2rem;
  font-weight: bold;
}
.thumbnail-holder{
  float: left;
  width:min-content;
  /* border:0.1rem solid black; */
}
.prev,.next{
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 1rem;
  margin-top: -0rem;
  color: white;
  font-weight: bold;
  font-size: 2rem;
  transition: 0.6s ease;
  user-select: none;
  -webkit-user-select: none;

}
.modal-content{
  position: relative;
  margin:auto;
  height: 70%;

}
.modal-footer{
    text-align: center;
    color:#fff;
    height:30%;
}
.thumbnail-img-wrap{
    text-align: center;
}
.image-wrap-modal img{
  height: -webkit-fill-available;
}.hideArrow {
  cursor: default;
  opacity: 0.25;
}
.noProductMessage {
  position: absolute;
  top:50%;
  left:0;
  right: 0;
  color:#cb0c0c;
}
.sliderDotsWrapprer {
  position: absolute;
  width: 100%;
  bottom: 3rem;
}
.sliderDotsWrapprer span {
  display: inline-block;
  position: relative;
  width: 0.75rem;
  height: 0.75rem;
  border: 1px solid #fff;
  border-radius: 50%;
  background-color: #ffffff;
  margin: 0 0.5rem;
  cursor: pointer;
}
.sliderDotsWrapprer span.activeDot {
  border: 2px solid #fff;
  background-color: #cb0c0c;
}
@media screen and (max-width:1140px){
  .image-wrap{
    width: 14rem;
  }
}
@media screen and (max-width:1024px){
  .image-wrap{
    width: auto;
  }
  .image-wrap-modal img{
    height: auto;
  }
}
@media screen and (max-width:767px){
  .image-modal{
  top: 114px;
  }
  .image-wrap{
    width: auto;
  }
  .image-wrap-modal img{
    width: auto;
    height: auto;
    margin-top:2rem;
    max-width: 100%;
  }
  .modal-content{
    height: auto;
  }
}