.dom-disclaimer{
    width: fit-content;
    border: 1px solid #98a0a0;
    color: #168c87;
    font-family: HelveticaNeueBold;
    padding:0.25rem;
    background-color: #c0f5e4;
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
    display: block;
}
.dom-card{
    width: -webkit-fill-available;
    margin-bottom:1rem;
    margin-right: 1rem;
    background-color: #ffffff;
    border:1px solid #d2d2d2;
    text-align: center;
    font-family: HelveticaNeue;
    font-weight: bold;
}
.dom-content{
    display: flex;
    flex-direction: column;
}
.dom-content{
    padding: 1.4rem;
    min-height: 9rem;
}
.dom-list-content {
    padding-top: 1.4rem;
    padding-right: 1.4rem;
    padding-left: 1.4rem;
}
.dom-card-title{
    cursor: pointer;
    font-family: HelveticaNeueBold;
    font-size:1.2rem;
    color:#2b77f2;
    text-decoration: underline;
}

.preview-circular-link,.participation-distr-link{
    cursor: pointer;
    font-family: HelveticaNeue;
    font-weight: bold;
    color:#2b77f2;
    text-decoration: underline;
    margin-left:1rem;
}
.dom-ship-window,.promoships-link{
    float: left;
    /* text-align: left; */
}

.dom-start-end-dates{
    float: right;
}
.promoships-link,.preview-link{
    cursor: pointer;
    color:#2b77f2;
    text-decoration: underline;
}
.region-label-dom{
    font-family: HelveticaNeueBold;
    font-weight: bold;
}
.dom-list-card{
    margin-bottom:1rem;
    background-color: #ffffff;
    border:1px solid #d2d2d2;
    font-family: HelveticaNeue;
    font-weight: bold;
}
.dom-list-card p,.dom-list-ship-window,.dom-list-start-end-dates{
    display: inline;
}
.dom-list-content{
    display: flex;
    /* justify-content: space-between; */
}
.dom-list-footer{
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
}
.dom-list-footer .preview-link,.dom-list-footer .promoships-link{
    padding-right: 1rem;
}
.dom-view-items{
    width: 20rem;
}
.dom-list-content .dom-deadline{
    position: absolute;
    left:40%;
}
.dom-list-ship-window{
    position: absolute;
    left:55%;
}
.dom-list-start-end-dates{
    position: absolute;
    right: 1.5rem;
}
.preview-img{
    max-width: 100%;
    height: auto;
}
.preview_circular_pdf{
    height: -webkit-fill-available;
    width: 100%;  
}
.dom-footer{
    margin-left: 257px;
    margin-right: -2rem;
    }
.dom-table{
    width: 100%;
}
.bold-text{
    font-weight: bold;
    margin-left: 1rem;
    color: #F79228;
}
.indicator-text{
    font-weight: bold;
    color: #F79228;
}
.icon-space{
    margin-left: 1rem;
}
.icon-space img {
    vertical-align: middle;
}
@media (min-width:300px) and (max-width: 771px){
    .dom-footer{
        margin-left: 1%;
        margin-right: 1rem;
    }
    
}
@media (min-width:986px) and (max-width: 1920px){
    .dom-footer{
        margin-left: 18%;
        margin-right: -1rem;
    }   
}
@media (min-width:770px) and (max-width: 985px){
    .dom-footer{
        margin-left: 24%;
        margin-right: -1rem;
    }
}
@media only screen and (max-width: 768px){
    #dom-ship-windowMobile{
        float: none;
    }
    .dom-card{
        margin: 0rem;
    }
    .dom-promolink-previewlink{
        margin-top: 0.5rem;
    }
}