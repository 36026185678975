@font-face {
  font-family: AntonioBold;
  src: url('../../fonts/Antonio-Bold.ttf');
}
@font-face {
  font-family: AntonioExtraLight;
  src: url('../../fonts/Antonio-ExtraLight.ttf');
}
@font-face {
  font-family: AntonioLight;
  src: url('../../fonts/Antonio-Light.ttf'); 
}
@font-face {
  font-family: AntonioMedium;
  src: url('../../fonts/Antonio-Medium.ttf'); 
}
@font-face {
  font-family: AntonioRegular;
  src: url('../../fonts/Antonio-Regular.ttf');
}
@font-face {
  font-family: AntonioSemiBold;
  src: url('../../fonts/Antonio-SemiBold.ttf');
}
@font-face {
  font-family: AntonioThin;
  src: url('../../fonts/Antonio-Thin.ttf');
}
@font-face {
  font-family: arial;
  src: url('../../fonts/arial.ttf');
}

@font-face {
  font-family: arialBold;
  src: url('../../fonts/G_ari_bd.TTF');
}
.header-top .user-menu-divider {
  margin: 0.3em;
  font-size: 1.4rem;
  line-height: 2em;
  border-top-color: #8d8d8e;
}

.header-top .view-menu {
  color: #ffffff;
}

.ui.dropdown.views-menu {
  width: 100%;
  padding-bottom: 0.5rem;
}
.ui.dropdown.views-menu + a {
  line-height: 1;
  padding-bottom: 0.25rem;
  padding-top: 0.25rem;
}
.space_ref{
  position: absolute;
  right: 3.8rem;
  height: 2.55rem;
}
.space_ref_top{
  position: absolute;
  right:0.1rem;
  height: 0.3rem;
  width: 4rem;
}
.header-top-filler{
  display: flex;
  flex-grow: 1;
}
.header_user_icons_text{
  font-size: 1rem;
  line-height: 1.1;
}
.header_user_icons_text .ui.dropdown div.text:empty {
  display: none;
}
.header_user_icons{
  display: flex;
  justify-content: space-around;
  align-items: baseline;
  white-space: nowrap;
  margin-right: 0.5rem;
}
.header_user_icons >*{
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.header-top > *{
  padding-left: 0.8rem !important;
  padding-right: 0.8rem !important;
}
.header_Logo{
  margin-right: 1rem;
  }
.header_user_icons span.personIcon {
color: #fff;
padding-right: 0;
}
.header_user_icons span.personIcon img {
  width: 2rem;
}
.header_user_icons span.personIcon i {
  font-size: 1rem;
}
.ui.top.right.pointing.dropdown>.menu {
  margin: -0.4em 0 0;
}
.name_card i {
  text-decoration: none;
}

/* !important is bad but cant avoid here */
.ui.dropdown.views-menu .menu > .item {
  padding: 9px !important;
  text-align: center;
}
.ui.dropdown.views-menu .menu > .item:hover {
  background-color: #3a3b3d;
}
.header_user_icons_text{
  margin-right: 1.5rem;
}
.header_user_icons i.icons .icon{
  position:unset;
  transform:initial;
}
.header-top {
  padding: 0.5rem 0.3rem !important;
  height: 38px;
}
.large.far.fa-barcode-read {
  font-size: 1.4rem;
}
.heroHeaderWrapper {
  display: flex;
  height: 100%;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem 1rem;
}
.heroHeader {
  overflow: true;
  position:fixed;
  width: 100%;
  top: 0;
  left:0;
  right:0;
  z-index: 200;
  justify-content: space-between;
  background-color: #222222;
  box-sizing: border-box;
  height: 4.5rem;
}
.needHelp {
  width: 20% !important;
  margin-left: 9rem;
  margin-bottom: 1rem !important;
  z-index: 1000;
}
.headerLogoImg {
  max-height: 100%;
  max-width: 100%;
}
.header_user_icons img {
  width: 2.5rem;
}
.header_user_icons_text p {
  color: #fff;
  font-size: 1rem;
  margin-bottom: 0;
  text-align: right;
  white-space: nowrap;
}
.header_user_icons_text p:nth-of-type(1) {
  font-family: arialBold;
}
.name-menu {
  font-family: arialBold;
  margin-top: -0.25rem !important;
}
#name-menu .user-menu-option {
  background: #000;
  border: 1px solid #fff;
}
.name_card {
  padding: 0.25rem 0 0.25rem 0.25rem;
}
.userInfoWrapper {
  display: flex;
  flex-wrap: nowrap;
  align-items: end;
}
.header_user_icons > * {
  margin: 0 0.5rem;
}
.header_user_icons_text {
  position: relative;
  padding: 0px 0.5rem;
  font-family: arial;
  margin-right: 0.5rem;
  text-align: right;
}
.header_user_icons_text:after {
  content: '';
  width: 1px;
  height: 100%;
  background: #fff;
  position: absolute;
  top: 0;
  right: 0;
}
#search_div {
  margin-top: 1rem;
}
.searchDiv {
  width: 25rem;
  position: relative;
  max-width: 100%;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
}
.searchDiv i.icon {
  position: absolute;
  left: 4rem;
  z-index: 1;
  top: 0.75rem;
  pointer-events: none;
}
.searchDiv select {
  border: 0;
  margin-right: -0.1rem;
  padding-left: 0.5rem;
  font-family: Arial, Helvetica, sans-serif;
  color: #222222;
  background-color: #fff;
  outline: none;
  width:6rem;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none
}
.mobileSearchDiv {
  width: 20rem;
  margin: 0.5rem;
}
.searchIcon_address {
  position: absolute;
  width: 2rem;
  height: 2rem;
  right: 0.3rem;
  top: 50%;
  margin-top: -1rem;
}
.searchDiv input {
  padding: 0.85rem;
  padding-right: 2.2rem;
  width: 100%;
  border-radius: 2px;
  border: none;
  outline: none !important;
}
.changeStorePopup {
  max-width: 45rem;
  top: 10rem;
  background: #f2f2f2;
}
.ui.modal.changeStorePopup>.content {
  margin-top: 1rem;
}
.changeStorePopup .close.icon {
  color: #000;
  top: 0.5rem!important;
  right: 0.5rem!important;
}
.changeStoreContainer {
  font-family: Arial, Helvetica, sans-serif;
}
.changeStoreContainer h3 {
  font-family: AntonioBold;
  font-size: 1.5rem;
  border-bottom: 1px solid #ccc;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
}
.changeStoreFields {
  display: flex;
  margin: 1rem 0;
}
.changeStoreFields input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.changeStoreFields button {
  font-family: AntonioBold;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: 1px solid #ada8a8;
  background: #c6112d;
  color: #fff;
  width: 4rem;
  height: 2.6rem;
  cursor: pointer;
}
.vendorPopup {
  max-width: 50rem;
}
.vendorPopup p {
 font-family: Arial, Helvetica, sans-serif;
 font-size: 0.8rem;
}
.vendorPopUpContent {
  overflow-y: auto;
    padding: 1rem 0;
}
.vendorPopUpContent a {
  color: #c6112d;
}
.vendorPopUpContent button {
  background-color: #c6112d;
  color: #fff;
  border: 0;
  padding: 0.5rem 0.5rem;
  cursor: pointer;
  
}
.headerNameStore {
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
  flex-wrap: nowrap;
}
.headerNameStore  >div.ui.dropdown {
  border-right: 1px solid #fff;
  margin-right: 0.5rem;
}
.ui.dropdown.adminDropDownMenu {
  display: block;
  color: #fff;
  text-transform: capitalize;
  line-height: 2em;
}
.adminDropDownSubMenu {
  background-color: #1a1c21e6 !important;
}
.ui.dropdown .menu.adminDropDownSubMenu .item {
  color: #fff;
  padding: 0.45rem 0.3rem 0.45rem 1rem !important;
}
.ui.dropdown .menu.adminDropDownSubMenu .item:hover {
  background: #3e3a3a;
}
.ui.dropdown.adminDropDownMenu i.icon, .ui.dropdown.views-menu i.icon {
  position: absolute;
  right: 0;
}
.ui.dropdown .menu .menu.adminDropDownSubMenu {
 margin-left: 0 !important;
}
.changeStoreContainer .closeBtnPopUp {
  display: flex;
  justify-content: flex-end;
}
.closeBtnPopUp button {
  cursor: pointer;
  background: #d5d3d3;
  border: 0;
  padding: 0.5rem 0.75rem;
  border-radius: 3px;
  line-height: 1;
  font-family: AntonioBold;
}
strong.boldFont {
  font-family: arialBold;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .ui.top.right.pointing.dropdown > .menu.user-menu {
    margin-right: 14px;
  }
  .ui.top.right.pointing.dropdown > .menu.user-menu > div {
    padding: 7px;
  }
  .ui.top.right.pointing.dropdown > .menu#user-menu:after {
    top: -8px !important;
    right: 8px !important;
  }
  .header-top-filler{
    display: none;
  }
  .header-top-left{
    flex-grow: 1;
  }
  .header_Logo{
    margin-right: 0rem;
  }
  .header-top > *{
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .header_user_icons_text{
    margin-right: 0rem;
  }
  .header-top{
    height: 86px;
    }
     .header-top-right{
    flex-grow: 1;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
  .needHelp {
    margin-left: 2rem !important;
  }
  .NotProd{
    margin-right: 0;
    margin-left: 8.5rem;
    margin-top: 0;
  }
  .header-top-filler{
    display: none;
  }
  .header-top-left{
    flex-grow:1;
  }
  .header-top-right{
    flex-grow: 1;
  }
  .header_Logo{
    margin-right: 0rem;
  }
  .header-top > *{
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .header_user_icons_text{
    margin-right: 0rem;
  }
  .header-top {
    height: 86px;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .NotProd{
    margin-left: 0rem;
    margin-right: -8rem;
  }
  .header-top-filler{
    display: none;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 1024px) {
  .header-store-name{
    display: none;
  }
}
  .needHelp {
    margin-left: 2rem !important;
  }
  .headerLogoImg {
    margin-bottom: 0rem;
  }
  .header_user_icons_text {
    padding: 0 0.5rem 0 0;
    align-items: end;
  }
  .header_user_icons > * {
    margin: 0 0.5rem;
  }
  .heroHeaderWrapper {
    flex-direction: column;
  }
  .heroHeader {
    height: 8rem;
  }
  .ui.pusher-menu.menu {
    top: 8rem;
  }
  #sidebar-native {
    top: 8rem !important;
  }
  .ui.dropdown .menu.adminDropDownSubMenu{
    position: static !important;
  }
  .ui.dropdown.adminDropDownMenu .icon.mobileUpArrow:before {
    content: "\f0d7";
  }
  .ui.dropdown.adminDropDownMenu .icon.mobileDownArrow::before {
    content: "\f0d8";
  }
  .vendorPopUpContent {
    max-height: 71vh;
    padding-bottom: 4rem;
  }
  
#admin-views-menu.ui.dropdown.adminDropDownMenu .menu.menu.user-viewoptions-menu {
  margin-left: -1.5rem !important;
  background: rgb(26,28,33) !important;
  background: linear-gradient(90deg, rgba(26,28,33,0) 10%, rgba(26,28,33,1) 10%) !important;
}


@media only screen and (min-width: 667px) and (max-width: 900px) and (orientation: landscape) { 
  .heroHeaderWrapper {
    flex-direction: column;
  }
}

@media only screen and (min-width: 766px) and  (max-width: 969px) and (orientation: landscape) { 
  .heroHeaderWrapper {
    flex-direction: column !important;
  }
  .header-top-right {
    justify-content: flex-end !important;
  }
}

@media only screen and (min-width: 967px) and (orientation: landscape) { 
  .heroHeaderWrapper {
    flex-direction: row !important;
    justify-content: space-between !important;
  }
}

@media only screen and (min-width: 768px)  and  (max-width: 912px) { 
  .header-top-right {
    justify-content: flex-end !important;
  }
}

@media only screen and (min-width: 1240px) and   (max-width: 1300px) and (orientation: landscape) { 
  .searchDiv {
    width: 21em;
  }
} 

@media only screen and (min-width: 1240px)  and  (orientation: landscape) { 
  .heroHeader {
    height: 4.5rem !important;
  }
}

@media only screen and (min-width: 768px)  and  (max-width: 967px) and  (orientation: landscape){ 
  .yellowAlertMsg {
    margin-top: 12rem !important;
  }
}

@media only screen and (min-width: 681px)  and  (max-width: 967px) and  (orientation: landscape){ 
  .yellowAlertMsg {
    padding: 1.5rem 1.5rem !important;
  }
}
@media only screen and (min-width: 967px)  and   (max-width: 1240px)  and  (orientation: landscape) { 
  .ui.pusher-menu.menu {
    top: 5rem !important;
  }
  .heroHeader {
    height: 5rem !important;
  }
}

@media (max-width:480px) {
  .header_user_icons_text {
    max-width: 60%;
  }
  .headerNameStore + p {
    white-space: inherit;
  }
  .header_user_icons_text p {
    font-size: 0.75rem;

  }
  .userInfoWrapper {
    width: 100%;
    justify-content: center;
  }
  .heroHeader {
    height: 6rem;
}
  .ui.pusher-menu.menu {
    top: 6rem;
  }
  #sidebar-native {
    top: 6rem !important;
}
.pusher.sidebar-pusher > div:nth-child(2) {
  margin-top: 9rem !important;
}
.ui.menu.quick-links .item a {
  font-size: 0.75rem;
}
.productShowcaseWrapper h2, div.wpHompageContent h2 {
 font-size: 2.5rem !important;
}
.productShowcaseWrapper > div > p {
  font-size: 0.9rem;
}
.toolsContentWrap p {
  font-size: 0.9rem;
}
.storyPlaceholderCol > div p, .storyPlaceholderCol > div a {
  font-size: 0.9rem;
}
.reunionQuickLinkContainer .reunionQuickLinkSection p > a {
  font-size: 1rem;
}
}
@media (max-width:1024px) {
  .storyPlaceholderCol {
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
@media (min-width:767px) {
 .needHelp {
  width: 25%;
  margin-top: 1rem;
 }
}
@media only screen and (min-device-width:768px) and (max-device-width:966px) and (-webkit-min-device-pixel-ratio: 2)
{
  .pusher.sidebar-pusher > div:nth-child(2) {
    margin-top: 11.4rem !important;
  }
} 

/*hereForYou */

.hereForYouDiv {
  margin: 1rem;
  margin-top: 0;
}
.hereForYouDiv .askTitle span {
 color: #b50a0a;
}
.page-header.hereForYouDiv {
  margin-top: 0;
  display: inline-flex;
    align-items: center;
    padding-bottom: 0;
    margin-bottom: 0;
}
.hereForYouDiv img {
  max-width: 11rem;
  margin-left: 1rem;
}
.hereForYouDiv h3 {
  font-size: 2rem;
  font-family: AntonioBold;
}
.hereForYouUpload {
  margin-left: 1rem;
}
.hereForYouUpload .choose-file-label {
  width: 16rem;
}
.askTitle b {
  font-size: 1rem;
}
.hereForYouDiv .reasonDropdown {
  height: auto;
  text-transform: none !important;
}
.hereForYouDiv option {
  text-transform: none !important;
}
.hereEmail {
  display: flex;
  padding-left: 1rem;
}
.hereEmail input {
  width: 3rem;
  height: 1.25rem;
}
.hereForYouDiv .askTitle{
  width: 17rem;
}
#hereForYouPopUp {
  position: fixed;
  display: none;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  background: hsla(0,0%,94.9%,.7)!important;
  z-index: 9999;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.hereForYouDiv .askform {
  padding-top: 0.75rem;
}
#hereForYouInfo {
  border: 0;
  box-shadow: none;
}
.page-header.hereForYouDiv.handleCloseHereForYou {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0;
  min-height: 4rem;
}
.handleCloseHereForYou .close {
  color: #000;
  position: relative;
  top: 0;
  right: 0;
}
.handleCloseHereForYou i {
  font-size: 1em;
}
.hereForYouInfoPoup {
  max-width: 52rem;
}
.hereForYouInfoPoup > i.close {
  display: none;
}
.sizeMsg {
  font-size: 0.75rem;
  max-width: 24rem;
  text-align: center;
  padding: 0.5rem 0.5rem 0 1rem;
}
.hereForYouDiv .autoform {
  height: auto;
}
.hereForYouDiv .emailTextMsg {
  font-size: 0.8rem;
  display: block;
  max-width: 34rem;
}
@media screen and (max-width: 767px) {

  .hereForYouDiv .autoform {
    padding-left: 0rem;
  }
  .hereForYouDiv .reasonDropdown {
    margin-left: 0rem;
    width: 15rem;
    
  }
  .hereForYouDiv .emailTextMsg {
    max-width: 17rem;
  }
  .hereForYouDiv .commentask {
    width: 15rem;
    margin-left: 0rem;
  }
  .hereForYouDiv .displayFlex {
    margin-bottom: 1rem;
  }
  .displayFlex.hereforYouSubmit {
    display: flex !important;
    width: 15.5rem;
  }

  .hereForYouUpload {
    margin-left: -1rem;
  }
}
@media(max-width:990px) {
  .hereForYouUpload .choose-file-label {
    width: 8rem;
  }
}

@media (min-width:1024px) {
  .hereForYouInfoPoup {
    max-width: 65rem;
}
  .hereForYouDiv .autoform,  .hereForYouDiv .askfield, .hereForYouDiv .reasonDropdown, .hereForYouDiv .commentask {
    width:35rem;
  }
  .hereForYouDiv .autoform.hereEmail {
    width: 13rem;
  }
  .hereForYouUpload .choose-file-label {
    width: 27rem;
  }
  .hereforYouSubmit {
    width: 100%;
    justify-content: center;
  }
}