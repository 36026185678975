.delete-icon {
  height: 1.25rem;
  cursor: pointer;
  width: 1.25rem;
  margin-right: 10px;
}
.update-icon {
  height: 1.25rem;
  cursor: pointer;
  width: 1.25rem;
}
#field_width_admin {
  border: none;
  column-width: 12rem;
}
#no_border_admin {
  border: none;
  column-width: 4rem;
}
#no_border_admin_del {
  border: none;
  column-width: 1rem;
}
#no_border_rarestriction {
  border: none;
  column-width: 6rem;
}
.renunion-link {
  padding-right: 5rem;
  margin-left: auto;  
  padding-top: 1rem;
}
.reunionButton{
  margin-left: 20% !important;
}
.date-admin{
  margin-left: 1.5rem !important;
}
.text-admin{
  margin-left: 1.2rem !important;
}
.text-admin.home-tile-text{
  width: 40rem;
}
.tableRow {
  background-color: rgb(242, 242, 242) !important;
  color: #000000 !important;
  text-align: center !important;
  text-decoration-color: #a3a3a0;
  padding: 0.3rem 0.2rem !important;
}
.activeTableRow{
  background-color:#eded60 !important
}
.tableCell{
  text-align: center !important;
  width: 8rem;
}
.rowHeader {
  background-color: rgb(242, 242, 242) !important;
  color: #000000 !important;
  text-align: center !important;
  text-decoration-color: #a3a3a0;
  padding: 0.3rem 0.2rem !important;
  width:8rem;
}
.promo-table{
  width: 95% !important;
  margin-top: 1rem !important;
}
.restriction-table{
  width: 60% !important;
  margin-top: 1rem !important;
}
.shoppingArea-table{
  width: 80% !important;
  margin-top: 1rem !important;
}
.limitQty-table{
  width: 35% !important;
  margin-top: 1rem !important;
}
#title_width{
  column-width: 1rem;
}
#title_width_logo{
  column-width: 10rem;
  border : none;
}
#title_width_limit{
  column-width: 4rem;
}
.select-admin{
  margin-left: 2rem !important;
}

.datePicker{
  margin-left: 0.4rem;
  margin-right: 1rem;
  width: 15.5rem;
}

.datePickerSale{
  margin-left: 0.8rem;
  margin-right: 1rem;
  width: 15.5rem;
}

.pl-15 {
  padding-left: 15.5rem;
}

.pt-3 {
  padding-top: 3rem;
}

.upload-link {
  padding-left: 3rem;
  padding-top: 1rem;
}
.date-format {
  margin-left: 9px !important;
}
.uploadManin {
  padding:0.25rem 0.25rem;
}
.uploadManin.uploadMainHomeTile {
  padding-left: 15rem;
  width: 85%;
}
.nameContainer {
  width: 20%;
  margin-right:1.5rem;
  direction: rtl
}
.uploadRoot{
  margin-bottom:0.75rem;
  display:flex
}
.uploadMenuOption {
  padding-left: 15rem;
  width: 94%;
  border: 0.2rem solid gray;
  padding: 18px;
  height:136px;
  margin-left: 3rem;
  margin-top: -2rem;
}
.cartReportForm {
  margin-left: 20%;
  margin-right: 20%;
  text-align: center;
}
.cartReportOption {
  border: 0.2rem solid gray;
}
.grid-container-cartReport {
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 10px;
}
.buttonMenuOption {
  margin-left: 3rem;
  margin-top: 0.5rem;
}
.uploadButtonContainer{
  width:65%;
  display:flex;
  align-items: baseline;
  justify-content: space-between;
}
.inputWidthContainer{
  width: 100%;
}
.saveShoppingArea {
  margin-left: 35% !important;
  margin-top: 1rem !important;
  height: 2.5rem;
  width: 6rem;
}

.saveRARestriction{
  margin-left: 20% !important;
  margin-top: 1rem !important;
  height: 2.5rem;
  width: 6rem;
}

#admin_tbl,#admin_RA_tbl,
  #admin_tbl thead,#admin_RA_tbl thead,
  #admin_tbl tbody,#admin_RA_tbl tbody,
  #admin_tbl th,#admin_RA_tbl th,
  #admin_tbl td,#admin_RA_tbl td,
  #admin_tbl tr,#admin_RA_tbl tr
  {
    background-color: rgb(242, 242, 242) !important;
    display: block;
  }

  #admin_tbl thead tr, #admin_RA_tbl thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  #admin_tbl tr, #admin_RA_tbl tr {
    margin: 0 0 1rem 0;
  }

  #admin_tbl td  {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eeeeee;
    position: relative;
    padding-left: 50% !important;
    line-height: 24px;
  }

  #admin_RA_tbl td{
    border: none;
    border-bottom: 1px solid #eeeeee;
    position: relative;
    padding-left: 90% !important;
    line-height: 24px;
  }

  .even-row td{
    padding-left: 0 !important;
  }

  .overFlow{
    overflow: auto;
  }

  .react-datepicker{
    margin-left: -12px !important;
  }
  #admin_tbl td:before, #admin_RA_tbl td:before{
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }

  #admin_tbl td:nth-of-type(1):before {
    content: 'Event Name';
  }
  #admin_tbl td:nth-of-type(2):before {
    content: 'Display Name';
  }
  #admin_tbl td:nth-of-type(3):before {
    content: 'Start Date';
  }
  #admin_tbl td:nth-of-type(4):before {
    content: 'End Date';
  }
  #admin_tbl td:nth-of-type(5):before {
    content: 'Time Zone';
  }
  #admin_tbl td:nth-of-type(6):before {
    content: 'Attendance Required';
  }
  #admin_tbl td:nth-of-type(7):before {
    content: 'Show Timer';
  }
  #admin_tbl td:nth-of-type(8):before {
    content: 'Logo';
  }

  #admin_RA_tbl td:nth-of-type(1):before {
    content: 'Asst Number';
  }
  #admin_RA_tbl td:nth-of-type(2):before {
    content: 'State';
  }
  #admin_RA_tbl td:nth-of-type(3):before {
    content: 'Country';
  }
  #admin_RA_tbl td:nth-of-type(4):before {
    content: 'RDC';
  }
  #admin_RA_tbl td:nth-of-type(5):before {
    content: 'Customer Type';
  }
  #admin_RA_tbl td:nth-of-type(6):before {
    content: 'Recommended Assortments  to purchase';
    white-space: pre;
  }
  .dropship_datepicker{
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .dropship_content{
      align-items: flex-start;
    flex-wrap: wrap;
    width: 100vw;
  }
  .dropship_content > div{
    width: 50%;
  }
  .dropship_button{
    margin-top: 1rem !important;
  }
.savePromoLinking {
  margin-left: 40% !important;
  margin-top: 1rem !important;
  height: 2.5rem;
  width: 6rem;
}

.saveLimitQty {
  margin-left: 13% !important;
  margin-top: 1rem !important;
  height: 2.5rem;
  width: 6rem;
}

@media screen and (max-width: 600px) {
  .upload-link {
    width: 100%;
  }
  .date-admin{
    margin-left: 0rem !important;
  }
  .displayFlex {
    display: grid !important;
}
}

@media (min-width: 300px) and (max-width:600px){
  .res-menupage{
    width: 65%;
  }
  .upload-link {
    padding-left: 2rem;
    padding-top: 1rem;
}
.uploadMenuOption{
  margin-left: 2rem;
}
}

@media (min-width: 300px) and (max-width:600px){
  .cartReportForm {
      margin-left: 5%;
      margin-right: 20%;
      text-align: center;
    }
}

@media (device-height : 568px) 
   and (device-width : 320px) 
   and (-webkit-min-device-pixel-ratio: 2)
 {
  .res-menupage{
    width: 70%;
  }
}
.pogLabelName{
  width:150px;
}