.normal-font {
  font-weight: normal;
}
.orderUploadWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.orderUploadWrap h2 {
  flex-basis: 100%;
  margin-bottom: 0rem;
}
.order-upload {
  padding: 0.5rem 1em;
  margin-top: 0;
  background-color: #f2f2f2;
  display: inline-block;
  max-width: 50%;
}
.order-upload .page-header p {
  font-family: HelveticaNeue;
  
}
.order-character {
  margin-top: 2rem;
  margin-left: 0.8rem;
}
.mb-2 {
  margin-bottom: 2rem;
}
.base-line {
  align-items: baseline;
}
.custom-file-input {
  width: 1rem;
  margin-left: -0.7rem;
}
.button.submit {
  width: 8rem;
  margin-top: -1.2rem;
}
.pl-20 {
  padding-left: 20.5rem;
}

.pt-4 {
  padding-top: 4rem;
}
.template-link {
  padding-top: 0.4rem;
}
#upload-file {
  margin: 0;
}
.mobileUploadBtn {
  justify-content: space-between;
  align-items: center;
  max-width: 40rem;
}
.mobileUploadBtn button.submit {
  height: 2.3rem;
  line-height: 2rem;
  padding: 0;
  margin: 0;
  width: 9rem !important;
}
.cipherBtn {
  width: 13.5rem !important;
}
.cipherLoaderWrap {
  position: fixed;
  top:0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #dcdcdcf7;
}
.cipherLoaderWrap > div {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media screen and (max-width: 560px) {
  .mobileUploadBtn {
    flex-wrap: wrap;
  }
  .upload-instructions-div {
    width: 100%;
  }
  .mobile-pt {
    justify-content: flex-end;
    padding-top: 2rem;
  }
}
@media screen and (max-width: 992px) {
  .order-upload  {
    max-width: 100%;
    margin-bottom: 1rem;
  }
}
