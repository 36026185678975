@font-face {
  font-family: HelveticaNeue;
  src: url('../../fonts/HelveticaNeue Light.woff2');
  src: url("../../fonts/HelveticaNeueCyr-Light.eot"),
  url("../../fonts/HelveticaNeueCyr-Light.woff") format("woff");
}
@font-face {
  font-family: HelveticaNeueMedium;
  src: url('../../fonts/HelveticaNeue Medium.woff2');
  src: url('../../fonts/Helvetica Neu Medium.woff');
}
@font-face {
  font-family: Franchise;
  src: url('../../fonts/Franchise-Bold.woff2');
  src: url("../../fonts/Franchise-Bold.eot"),
  url("../../fonts/Franchise-Bold.woff") format("woff");
}
@font-face {
  font-family: HelveticaNeueBold;
  src: url('../../fonts/Helvetica Neu Bold.woff2');
  src: url("../../fonts/HelveticaNeueCyr-Bold.eot"),
  url("../../fonts/HelveticaNeueCyr-Bold.woff") format("woff") ;
}
.viewNames {
  font-family: HelveticaNeue;
  font-size: 0.9rem;
  letter-spacing: 0.05rem;
}
.viewRequiredFields {
  font-family: HelveticaNeue;
  font-size: 0.8rem;
}
.requiredField{
  margin-left: 2rem;
}
.borderAddressbook{
  margin-left: 8rem;
  color:#c8102e;
}
#disabled{
  cursor: not-allowed;
  color: #919191;
  text-decoration: none;
}
.viewNamesInput {
  font-family: HelveticaNeue;
  font-size: 0.9rem;
  width: 12.5rem;
}
.custom-input-btn {
  padding: 0.2rem 0.6rem;
  height: auto;
}
.custom-input-btn-cancel {
  background-color: #ffffff;
  color: #c8102e;
  font-family: HelveticaNeue;
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  border: 2px solid #db2828;
  margin-left: 4rem;
}
.custom-input-btn-save {
  background-color: #db2828;
  color: #fff;
  font-family: HelveticaNeue;
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  border: 2px solid #db2828;
  margin-left: 0.5rem;
}
#greyoutBtn{
  opacity: 0.5;
  cursor: default;
}
#upload-file {
  width: 1rem;
  margin-left:0;
}
.custom-file-input-cancel {
  background: none;
  border: none;
  padding: 0;
  opacity: 0;
  display: inline;
}
.custom-input-btn-cancel-address {
  background-color: #ffffff;
  color: #c8102e;
  font-family: HelveticaNeue;
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  border: 2px solid #db2828;
  margin-left: 1rem;
}
.custom-file-input-save {
  background: none;
  border: none;
  padding: 0;
  opacity: 0;
  display: inline;
}

#phone.AddViewTabphone1,
#fax.AddViewTabFax1 {
  padding-top: 0rem;
  padding-right: 0rem;
  width: 5rem;
}

.ui.grid p.separator {
  padding: 0rem 0rem;
}
.phoneField,
.faxField {
  width: 3.7rem;
  font-family: HelveticaNeueMedium;
  font-size: 0.9rem;
}
.ZipField {
  font-family: HelveticaNeueMedium;
  font-size: 0.9rem;
  width: 5rem;
}

.ui.grid#phone-field > .AddViewTabphone2,
.ui.grid#fax-field > .AddViewTabFax2 {
  width: 4rem;
  padding-right: 0rem;
  padding-top: 0rem;
  padding-left: 0rem;
}
#zip-field .AddViewTabZip2 {
  padding-top: 0rem;
  padding-left: 0rem;
}
.ui.grid.AddViewSegment > .AddViewTab {
  padding-top: 0rem;
}
.ui.grid.AddViewSegment {
  padding-left: 1rem;
}
.ui.grid.AddEditSegment {
  padding-top: 1rem;
  padding-left: 1rem;
}
.AddEditTitile {
  font-family: HelveticaNeueBold;
  font-size: 1rem;
}
.bulkuploadSegment {
  padding-left: 0px;
  padding-right: 0px;
}
.bulkuploadSegment .file_div {
  margin-left:  0rem;
}
.file_div {
  text-align: left;
  display: inline-block;
}

i.big.icon {
  vertical-align: bottom;
}
#address_search{
  font-family: HelveticaNeue;
  margin-top: -0.5rem;
  /* font-weight: bold; */
}
#address_search .column {
  width: auto;
}
#address_search .FindAddress {
  font-size: 1.2rem;
  padding-right: 0;
}
#address_search .column.addnewSegment {
  padding-left: 0;
  padding-right: 0;
}
#address_search .column.addnewSegment .add-new {
  padding-top:0;
}
#Add-file {
  position: absolute;
}
.AddressTableHead {
  background-color: #d2d2d2;
}
.AddressTableHead th {
  text-align: left;
  padding: 0.2rem 0.3rem;
  font-size: 1rem;
  font-family: HelveticaNeueMedium;
}
.AddressTableHead th:last-child {
  text-align: center;
}

#Address_table td {
  font-family: HelveticaNeue;
  font-weight: bold;
  border-bottom: 2px solid #919191;
  padding: 0.1rem 0.3rem;
}

#Address_table td:last-child {
  text-align: center;
}
#Address_table {
  border-collapse: collapse;
  width: 100%;
  border-radius: 0;
  font-size: 0.8rem;
  color: #000000;
}
.page-dd-title {
  font-family: HelveticaNeue;
  font-weight: bold;
  font-size: 1rem;
  margin-right: 0.5rem;
}
.ui.selection.dropdown {
  min-width: 3rem;
  min-height: 1.4rem;
  padding: 0.2rem;
  font-size: 1rem;
  border-radius: 0px;
}
.ui.selection.dropdown > .dropdown.icon {
  padding: 5px;
}
div.block-h.grid {
  margin: 1.4rem 1rem;
  height: 2.1rem;
}
.ui.grid.block-h > div.column {
  padding: 0px;
}

#Address_table tbody {
  background: #ffffff;
}
.deletelabel {
  padding-right: 0.3rem;
}
.ui.pagination.menu {
  font-size: 1rem;
}
.export_div {
  font-family: HelveticaNeueBold;
  /* font-weight: bold; */
  float: right;
  margin-right: 1rem;
}
.export_div p {
  font-size: 1rem;
}
.export_div p i {
  font-size: 1.5rem;
}
.choose-file-label {
  width: 9rem;
  height: 1.8rem;
  margin: auto;
}

#delete_address_check {
  display: inline-block;
}
.view_edit_place {
  font-family: HelveticaNeueBold;
  float: right;
  padding-right: 1rem;
}
.download_template {
  padding-left: 1rem;
  display: inline-block;
}
.delete_selected {
  text-decoration: underline;
}
.View_address {
  color: #4183c4;
  cursor: pointer;
  text-align: right;
  text-decoration: underline;
  font-weight: bold;
}

.search_icon_address {
  width: 1.3rem;
  height: 1.3rem;
  position: relative;
  right:1.8rem;
  top: 0.2rem;
  bottom: 0.01rem;
  z-index: 1;
  margin-bottom: 0.3rem;
  cursor: pointer;
}
.search_icon_address img {
  cursor: pointer;
  width: 100%;
}

.AddressTableSegment {
  padding: 0rem 1rem;
  margin-top: 0.4rem;
}
#bottom-pagination {
  margin-top: 0.4rem;
}
.bulkupload {
  font-family: HelveticaNeue;
  font-size: 1.0rem;
  display: inline-block;
  vertical-align: middle;
}
.bulkupload span {
  padding-right: 1rem;
}
.add-new {
  font-family: HelveticaNeue;
  font-size: 1.2rem;
  font-size: 1rem;
  display: inline-block;
  padding-top: 0.3rem;
  vertical-align: middle;
}
.add-new span {
  font-size: 1rem;
}
#search_div_address {
  align-items: center;
  position: relative;
}

.orders_header {
  padding-left: 0rem !important;
}

#search_div_address .search-box_address {
  width: 9.5rem;
  margin-left: 0.2rem;
  height: 1.5rem;
  border-radius: 0rem;
  padding-left: 0.625rem;
  font-size: 1rem;
  float: left;
}

#addNewBtn {
  min-width: 6rem;
  text-align: center;
  margin-left: 1rem;
  display: inline-block;
}
.address_found {
  font-family: HelveticaNeue;
  /* font-weight: bold; */
}
.select-address {
  color: #4183c4;
  text-decoration: underline;
  cursor: pointer;
}

#pagination > div.delete-menu,
#bottom-pagination div.delete-menu {
  text-align: right;
  padding-right: 2.1rem;
}
.errorMessage{
  border: 1px solid #FF0000;
}
.hoverTipIn{
  display:block;
  position:absolute;
  color:#4183c4
}
.hoverTipOut{
  display:none;
  width:0px;
  border:none;
}
.address-upload td {
  height: 3rem;
  vertical-align: top;
}
/* ipad styles go here */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .borderAddressbook {
    margin-left: 0rem; 
    color: #c8102e;
    display: block;
  }
  .hoverTipIn {
    margin-left: -6rem;
  }
  .bulkuploadSegment .file_div {
    margin-left: -0.5rem;
  }
  #pagination > div.delete-menu, #bottom-pagination div.delete-menu {
    padding-right: 1.1rem;
    text-align: left;
  }
  #address_search .column {
    width: auto!important;
    padding: 1rem 0px !important;
  }
  .AddressTableSegment {
    padding: 0rem 0rem;
  }
  #upload-file {
    margin-left: 2rem;
  }
  .choose-file-label {
    width: 7rem;
  }
  .fav-footer .ui.pagination.menu {
    margin-left: 0rem;
  }
  #address_search .ui.input,
  #address_search .ui.input > span,
  #address_search .ui.input > input,
  #address_search .ui.input > label {
    display: block;
    text-align: left;
    margin-left: 0;
  }
  #address_search input#Add-file {
    display: none;
  }
  #Add-file {
    width: 1.5rem;
    margin-left: -1.5rem;
  }
}

#limit-dropdown .menu > .item {
  background-color: #ffffff;
  color: #000000;
}
#limit-dropdown .ui.selection.dropdown {
  width: 3.75rem;
  padding-left: 5px;
}

.add-address input,
.add-address select {
  border: 1px solid #000000;
}

.error {
  color: #ff0000;
}
.address-book .right-align {
  text-align: right;
}
table.address-upload{
  table-layout: fixed;
}

table.address-upload td>input{
  width: 100%;
}

table.address-upload th{
  text-overflow: ellipsis;
    overflow: hidden;
}

.vertical-spaced{
  margin: 2rem 0;
}


@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  .bulkuploadSegment .file_div {
      margin-left: -0.5rem;
  }
  .borderAddressbook {
    margin-left: 0rem; 
    color: #c8102e;
    display: block;
  }
  .hoverTipIn {
    margin-left: -6rem;
  }
  #pagination > div.delete-menu, #bottom-pagination div.delete-menu {
    text-align: left;
    padding-right: 1.1rem;
  }
  #address_search .column {
    width: auto!important;
    padding: 1rem 0px !important;
  }
  .AddressTableSegment {
    padding: 0rem 0rem;
  }
  #upload-file {
    margin-left: 2rem;
  }
  .choose-file-label {
    width: 7rem;
  }
  .fav-footer .ui.pagination.menu {
    margin-left: 0rem;
  }
  .data-grid.address-upload td:nth-of-type(1):before {
    content:'Name *';
  }
  .data-grid.address-upload td:nth-of-type(2):before {
    content:'Attention to *';
  }
  .data-grid.address-upload td:nth-of-type(3):before {
    content:'Address Line 1 *';
  }
  .data-grid.address-upload td:nth-of-type(4):before {
    content:'Address Line 2';
  }
  .data-grid.address-upload td:nth-of-type(5):before {
    content:'City *';
  }
  .data-grid.address-upload td:nth-of-type(6):before {
    content:'State *';
  }
  .data-grid.address-upload td:nth-of-type(7):before {
    content:'Zip *';
  }
  .data-grid.address-upload td:nth-of-type(8):before {
    content:'Area';
  }
  .data-grid.address-upload td:nth-of-type(9):before {
    content:'Phone Area';
  }
  .data-grid.address-upload td:nth-of-type(10):before {
    content:'Phone Exchange';
  }
  .data-grid.address-upload td:nth-of-type(11):before {
    content:'Phone Line';
  }
  .data-grid.address-upload td:nth-of-type(12):before {
    content:'Fax Area';
  }
  .data-grid.address-upload td:nth-of-type(13):before {
    content:'Fax Exchange';
  }
  .data-grid.address-upload td:nth-of-type(14):before {
    content:'Fax Line';
  }
  .data-grid.address-upload td:nth-of-type(15):before {
    content:'Email';
  }
  .data-grid.address-upload td:nth-of-type(15) {
    line-height: 2rem;
  }
}

@media print {
  .print-component {
    padding: 1rem;
  }
  .ui.header .icon:only-child {
    display: none;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .search_icon_address {
    bottom:0.2rem;
  }
}