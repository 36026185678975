@font-face {
  font-family: arialBold;
  src: url('../../fonts/G_ari_bd.TTF');
}
.rc-menu {
  border: 0;
  box-shadow: none;
  margin: 0;
  font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
}

.rc-menu-horizontal > .rc-menu-item,
.rc-menu-horizontal > .rc-menu-submenu > .rc-menu-submenu-title {
  padding: 10px 13px;
  vertical-align: middle;
  line-height: 1;
}

.NeedHelp{
  color: yellow;
}

#help{
  color: yellow;
}
#NeedHelp\$Menu .rc-menu-submenu-title,#NeedHelp\$Menu .rc-menu-item{
  color: #000;
}
.rc-menu-horizontal {
  background: #c8102e;
  color: #222222;
  border: 0;
  font-family: arialBold !important;
  text-align: center;
  text-transform: uppercase;
}
.rc-menu-horizontal > li.rc-menu-item {
  font-size: 1rem;
}
.search_icon {
  right: 20px;
  bottom: auto;
}

.rc-menu-item-active,
.rc-menu-submenu-active > .rc-menu-submenu-title,
.rc-menu-horizontal > .rc-menu-submenu-active,
.rc-menu-horizontal > .rc-menu-item-active {
  border-bottom: 0;
  background-color: #C8102E;
  color: #ffffff;
  z-index: 9;
}
a .rc-menu-vertical > .rc-menu-item,
.rc-menu-vertical-left > .rc-menu-item,
.rc-menu-vertical-right > .rc-menu-item,
.rc-menu-inline > .rc-menu-item,
.rc-menu-vertical > .rc-menu-submenu > .rc-menu-submenu-title,
.rc-menu-vertical-left > .rc-menu-submenu > .rc-menu-submenu-title,
.rc-menu-vertical-right > .rc-menu-submenu > .rc-menu-submenu-title,
.rc-menu-inline > .rc-menu-submenu > .rc-menu-submenu-title {
  padding: 6px 24px 6px 24px;
}

.rc-menu-vertical > .rc-menu-item, 
.rc-menu-vertical-left > .rc-menu-item,
.rc-menu-vertical-right > .rc-menu-item,
.rc-menu-inline > .rc-menu-item, .rc-menu-vertical > .rc-menu-submenu > .rc-menu-submenu-title,
.rc-menu-vertical-left >.rc-menu-submenu > .rc-menu-submenu-title,
.rc-menu-vertical-right > .rc-menu-submenu > .rc-menu-submenu-title, 
.rc-menu-inline > .rc-menu-submenu > .rc-menu-submenu-title {
  padding: 6px 24px 6px 24px !important;
}

.rc-menu-sub.rc-menu-inline > .rc-menu-item,
.rc-menu-inline-left > .rc-menu-item,
.rc-menu-inline-right > .rc-menu-item,
.rc-menu-inline > .rc-menu-item,
.rc-menu-inline > .rc-menu-submenu > .rc-menu-submenu-title,
.rc-menu-inline-left > .rc-menu-submenu > .rc-menu-submenu-title,
.rc-menu-inline-right > .rc-menu-submenu > .rc-menu-submenu-title,
.rc-menu-sub.rc-menu-inline > .rc-menu-submenu > .rc-menu-submenu-title {
  padding: 8px 30px 8px 12px;
  min-width: 260px;
  max-width: 700px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
.rc-menu-inline .rc-menu-submenu-arrow {
  font-size: 24px;
}
.rc-menu-vertical.rc-menu-sub,
.rc-menu-vertical-left.rc-menu-sub,
.rc-menu-vertical-right.rc-menu-sub {
  max-height: 90vh;
  overflow-y: auto;
  border-radius: 0;
}
.rc-menu-vertical.rc-menu-sub > *,
 	 .rc-menu-vertical-left.rc-menu-sub > *,
  .rc-menu-vertical-right.rc-menu-sub > *{
    direction: ltr;
}

i.rc-menu-submenu-arrow {
  font-family: Dropdown;
  line-height: 1;
  margin-left: 3px;
  height: 1em;
  width: 1.23em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  width: auto;
  transform-origin: center;
  right: 13px;
}

.rc-menu-inline i.rc-menu-submenu-arrow {
  right: 13px;
}
.rc-menu-item-active, .rc-menu-submenu-active > .rc-menu-submenu-title, .rc-menu-horizontal > .rc-menu-submenu-active, .rc-menu-horizontal > .rc-menu-item-active {
  /*border-bottom: none !important;*/
}
.rc-menu-submenu .rc-menu > li {
  background-color: #f0ecec;
  font-family: arialBold;
  border-right: 0;
  border: 1px solid #c7c2c2;
}
.rc-menu-vertical .rc-menu-submenu-arrow {
  color:#C8102E;
}
.rc-menu-submenu-popup .rc-menu-submenu-active > .rc-menu-submenu-title {
  background-color: #ccc;
  color: #C8102E;
}
.rc-menu-submenu-popup .rc-menu-item-active {
  background-color: #fff;
  color: #C8102E;
}

.rc-menu-horizontal > .rc-menu-submenu, .rc-menu-horizontal > .rc-menu-item {
  border-bottom: 0 !important;
}

@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  .menu .sidebar {
    padding-top: 0;
  }
  .ui.menu {
    margin: 0;
  }
}

/* Mobile stayles */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .rc-menu-vertical > .rc-menu-item,
  .rc-menu-vertical-left > .rc-menu-item,
  .rc-menu-vertical-right > .rc-menu-item,
  .rc-menu-inline > .rc-menu-item,
  .rc-menu-vertical > .rc-menu-submenu > .rc-menu-submenu-title,
  .rc-menu-vertical-left > .rc-menu-submenu > .rc-menu-submenu-title,
  .rc-menu-vertical-right > .rc-menu-submenu > .rc-menu-submenu-title,
  .rc-menu-sub.rc-menu-inline > .rc-menu-submenu > .rc-menu-submenu-title,
  .rc-menu-sub.rc-menu-inline > .rc-menu-item {
    padding: 8px 40px 8px 12px;
    max-width: 320px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .rc-menu-sub.rc-menu-inline > .rc-menu-item,
  .rc-menu-sub.rc-menu-inline > .rc-menu-submenu > .rc-menu-submenu-title {
    padding-right: 40px;
  }
  i.rc-menu-submenu-arrow {
    top: 8px;
  }
}
.rc-menu > li {
  background: #C8102E;
  color: #222222;
  cursor: pointer;
}


/* 
2: Home
4: Products
6: Orders
8: Vendors
10: Favourite
 */
.home .rc-menu.rc-menu-horizontal > li:nth-of-type(2),
.categories .rc-menu.rc-menu-horizontal > li:nth-of-type(4),
.retailAssortments .rc-menu.rc-menu-horizontal > li:nth-of-type(4),
.deals-of-the-month .rc-menu.rc-menu-horizontal > li:nth-of-type(4),
.listing .rc-menu.rc-menu-horizontal > li:nth-of-type(4),
.planograms .rc-menu.rc-menu-horizontal > li:nth-of-type(4),
.orderpad .rc-menu.rc-menu-horizontal > li:nth-of-type(6),
.allorders .rc-menu.rc-menu-horizontal > li:nth-of-type(6),
.vendors .rc-menu.rc-menu-horizontal > li:nth-of-type(8),
.favorites .rc-menu.rc-menu-horizontal > li:nth-of-type(10),
.favoriteVendors .rc-menu.rc-menu-horizontal > li:nth-of-type(10) {
  background-color: #C8102E;
}

li.rc-menu-submenu.rc-menu-submenu-open.rc-menu-submenu-active
  .rc-menu-submenu {
  z-index: 10;
}

i.rc-menu-submenu-arrow:before {
  content: '\f0d7';
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rc-menu-vertical.rc-menu-sub,
.rc-menu-vertical-left.rc-menu-sub,
.rc-menu-vertical-right.rc-menu-sub {
  max-height: 120vh;
  overflow-y: auto;
  border-radius: 0;
}
}