.prederenceCheck select{
    width:100%;
}
.redpreference{
    background-color: red;
    color:#fff!important;
}
@media screen and (max-width: 768px) {
    .prefereceSingle {
        width: 90% !important; 
    }
    .prederenceCheck select{ 
        width: 98%;  
    } 
  }